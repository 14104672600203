import { Text, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/Button";
import imgPerdaRoubo from "../../assets/img/perda-roubo.svg";
import imgCadeado from "../../assets/img/cadeado-branco.svg";
import imgCancelar from "../../assets/img/cancelar-branco.svg";
import { UseModal } from "../../Hook/Usemodal";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { Container } from "./styles";

export function PerdaRoubo() {
    const { state, setState, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const history = useHistory();
    const width = window.screen.width;

    return (
        <Container>
            <div className="container-img">
                <img src={imgPerdaRoubo} alt="" />
            </div>
            <Text as="p">
                Em caso de <strong> PERDA ou ROUBO,</strong> <br />
                você pode realizar o
            </Text>

            <Box width="90%" m="0 auto">
                {cartao?.status === "ATIVO" ? (
                    <Button
                        onClick={() => {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                acaoBloquearCartao: true,
                            });
                        }}
                    >
                        <img src={imgCadeado} alt="" /> BLOQUEio TEMPORÁRIO
                    </Button>
                ) : null}

                <Button
                    onClick={() => {
                        if (width <= 480) {
                            history.push("/cancelar-cartao");
                            handleClose("perdaRoubo");
                        } else {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                segundaVia: true,
                            });
                        }
                    }}
                >
                    <img src={imgCancelar} alt="" /> CANCELAR CARTÃO
                </Button>
            </Box>
        </Container>
    );
}
