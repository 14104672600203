import { useEffect, useState } from "react";

import { Text, Button } from "alisson-application";
import Mask from "react-input-mask";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import Modal from "../../ModalAlert";
// import { Button } from "../../Button";

import { Myform, initalValues, validadeForm } from "./config";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";
import { FormStyles } from "./styles";
import { ButtonUpload } from "../../ButtonUpload";
import { ButtonUploadDefault } from "../../ButtonUpload/button";

export type keyOf<T> = keyof T;

export function FormWhiteList() {
    const [file, setFile] = useState<File>({} as File);
    const [values, setValues] = useState<Myform>(initalValues);
    const [modalAprovar, setModalAprove] = useState(false);
    const [modalReprovar, setModalReprove] = useState(false);
    // const [loading, setLoading] = useState(false);
    const { whiteList, aprovar, reprovar } = useWhiteList();

    useEffect(() => {
        if (Object.keys(whiteList).length) {
            setValues({
                name: whiteList.name,
                document: whiteList.document,
                file: whiteList.file,
            });
        }
    }, [whiteList]);

    return (
        <FormStyles>
            <Formik
                initialValues={values}
                validationSchema={validadeForm}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    // setLoading(true);
                    // values = {
                    //     ...values,
                    //     document: values.document.replace(/[^\d]+/g, ""),
                    // };
                    // const formData = new FormData();
                    // const keys = Object.keys(values) as keyOf<Myform>[];
                    // keys.forEach((key) => {
                    //     if (key !== "file") {
                    //         formData.append(key, values[key] as string);
                    //     }
                    // });
                    // if (file.size) {
                    //     formData.append("file", file ? file : "");
                    // }
                    // try {
                    //     const response = await update(formData);
                    //     if (response.status === 200) {
                    //         history.push("/sistema/white-list");
                    //     }
                    // } catch (error) {
                    //     console.log(error);
                    // }
                    // setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue, handleChange, values } = props;
                    const { document } = values;

                    return (
                        <Form>
                            <div className="container-input-system user disabled">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="name"
                                />
                            </div>
                            <ErrorMessage component="span" name="name" />

                            <div className="container-input-system user disabled">
                                <Mask
                                    mask="999.999.999-99"
                                    placeholder="CPF"
                                    name="document"
                                    type="tel"
                                    onChange={handleChange}
                                    value={document}
                                />
                            </div>
                            <ErrorMessage component="span" name="document" />

                            <ButtonUpload
                                config={{
                                    accept: "image/*,application/pdff",
                                    id: "img-whatsapp",
                                }}
                                onChange={(values) => {
                                    if (values) {
                                        setFile(values[0]);
                                        setFieldValue("file", "ok");
                                    }
                                }}
                            >
                                <ButtonUploadDefault name={file?.name} />
                            </ButtonUpload>
                            <ErrorMessage component="span" name="file" />

                            <div className="container-btns">
                                <Button
                                    type="button"
                                    variant="secondary"
                                    onClick={() => setModalAprove(true)}
                                >
                                    Aprovar
                                </Button>
                                <Button
                                    type="button"
                                    variant="failure"
                                    onClick={() => setModalReprove(true)}
                                >
                                    Reprovar
                                </Button>
                            </div>

                            {values.file ? (
                                <button
                                    type="button"
                                    onClick={() =>
                                        window.open(values.file, "Download")
                                    }
                                >
                                    <Text>Download arquivo</Text>
                                </button>
                            ) : (
                                <></>
                            )}

                            {/* <Box width="220px" mt="15px" ml="auto">
                                <Button
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    type="submit"
                                    disabled={!props.isValid}
                                >
                                    Editar
                                </Button>
                            </Box> */}
                        </Form>
                    );
                }}
            </Formik>

            <Modal
                handleClose={() => setModalAprove(false)}
                reject={() => setModalAprove(false)}
                aceept={async () => {
                    await aprovar(1);
                    setModalAprove(false);
                }}
                title="Aprovar"
                description="Deseja aprovar este usuário?"
                open={modalAprovar}
            />
            <Modal
                handleClose={() => setModalReprove(false)}
                reject={() => setModalReprove(false)}
                aceept={async () => {
                    await reprovar("");
                    setModalReprove(false);
                }}
                title="Reprovar"
                description="Deseja reprovar este usuário?"
                open={modalReprovar}
            />
        </FormStyles>
    );
}
