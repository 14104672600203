
export const TimeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
      <circle cx="38.0585" cy="38.5524" r="38.0585" fill="#FCB614"/>
      <path d="M42.3603 53.4022C42.0748 53.4733 41.7838 53.5365 41.495 53.5897C40.7399 53.7301 40.2407 54.4563 40.3804 55.2121C40.4493 55.5839 40.6605 55.8936 40.9472 56.0957C41.2427 56.3037 41.6187 56.3976 42.0019 56.3263C42.3459 56.2625 42.6926 56.1874 43.0328 56.1025C43.7784 55.9169 44.2327 55.1615 44.0466 54.4163C43.8611 53.6703 43.1064 53.2165 42.3603 53.4022Z" fill="white"/>
      <path d="M52.6499 34.7502C52.7474 35.044 52.9335 35.2838 53.1686 35.4495C53.5171 35.695 53.9731 35.7776 54.4083 35.6337C55.138 35.3914 55.5333 34.6046 55.2919 33.8753C55.1819 33.5429 55.0599 33.2094 54.9299 32.8847C54.6445 32.1711 53.8351 31.8237 53.1212 32.1091C52.408 32.3944 52.0604 33.204 52.3461 33.9178C52.4553 34.1908 52.5576 34.4709 52.6499 34.7502Z" fill="white"/>
      <path d="M46.8806 51.4156C46.6352 51.5777 46.3818 51.7345 46.1266 51.8815C45.4607 52.2656 45.2327 53.1167 45.6166 53.7823C45.7208 53.9633 45.8597 54.1113 46.0196 54.2244C46.4488 54.5264 47.0318 54.5717 47.5172 54.2921C47.8206 54.117 48.1223 53.9309 48.4147 53.7376C49.0556 53.3141 49.2321 52.4508 48.8084 51.8095C48.3848 51.1681 47.5218 50.9919 46.8806 51.4156Z" fill="white"/>
      <path d="M56.1564 38.6367C56.1262 37.8687 55.4793 37.2709 54.711 37.3009C53.9437 37.3312 53.3455 37.9783 53.3756 38.746C53.3871 39.0396 53.3901 39.3375 53.3833 39.6307C53.3726 40.1124 53.6081 40.5417 53.9742 40.7997C54.1923 40.9533 54.457 41.0463 54.744 41.0528C55.512 41.0698 56.1485 40.4607 56.1655 39.6923C56.1732 39.3419 56.1703 38.9869 56.1564 38.6367Z" fill="white"/>
      <path d="M52.4232 47.7552C51.8073 47.2931 50.936 47.4185 50.4748 48.0333C50.2981 48.2687 50.1121 48.5012 49.9216 48.7251C49.4238 49.31 49.4942 50.1884 50.079 50.6866C50.1123 50.7149 50.146 50.7408 50.181 50.7652C50.7624 51.1752 51.5705 51.081 52.0406 50.5293C52.2681 50.2622 52.4898 49.9844 52.7009 49.7032C53.1622 49.0885 53.0374 48.2166 52.4232 47.7552Z" fill="white"/>
      <path d="M54.4679 42.7247C53.7346 42.4948 52.9537 42.903 52.7239 43.6363C52.636 43.9164 52.5385 44.1979 52.4333 44.4736C52.2022 45.0805 52.4236 45.7482 52.9311 46.106C53.0241 46.1714 53.1267 46.2269 53.2381 46.269C53.956 46.543 54.76 46.1828 55.0337 45.4645C55.1586 45.1367 55.2745 44.8018 55.3794 44.4688C55.6089 43.7353 55.201 42.9547 54.4679 42.7247Z" fill="white"/>
      <path d="M36.2882 53.6019C35.0442 53.3786 33.8507 52.9982 32.7191 52.4677C32.7057 52.4607 32.6937 52.4527 32.6796 52.4463C32.413 52.3208 32.1467 52.1863 31.8887 52.0459C31.8878 52.0448 31.8861 52.0441 31.8847 52.0435C31.4113 51.7828 30.9492 51.4943 30.5003 51.1783C23.9545 46.5673 22.3807 37.4905 26.992 30.9448C27.9947 29.522 29.2081 28.3349 30.561 27.3941C30.5776 27.3825 30.5943 27.371 30.6108 27.3593C35.3779 24.0747 41.8508 23.8533 46.9127 27.2286L45.8255 28.7994C45.5233 29.2366 45.7092 29.5552 46.2383 29.5076L50.9607 29.0848C51.4905 29.0372 51.8073 28.5789 51.6649 28.0673L50.3967 23.4982C50.2548 22.986 49.8913 22.9247 49.5887 23.3618L48.499 24.9364C44.7843 22.4427 40.3289 21.4919 35.9066 22.2587C35.4612 22.3358 35.0221 22.4302 34.5889 22.54C34.5856 22.5406 34.5829 22.541 34.5802 22.5416C34.5635 22.5457 34.5465 22.5511 34.5302 22.5556C30.7168 23.5349 27.3897 25.759 25.0156 28.9323C24.9956 28.9561 24.975 28.9793 24.9561 29.0051C24.8771 29.1114 24.7988 29.2202 24.722 29.329C24.5966 29.5073 24.4729 29.69 24.3546 29.8728C24.3398 29.8948 24.3285 29.9172 24.3156 29.9395C22.3564 32.9754 21.4114 36.4852 21.5735 40.0584C21.5739 40.0701 21.5732 40.0819 21.5735 40.094C21.5892 40.443 21.6169 40.7969 21.6544 41.1453C21.6565 41.1678 21.6614 41.1891 21.6652 41.2115C21.7041 41.5618 21.7528 41.913 21.8139 42.264C22.4348 45.8458 24.1244 49.069 26.6563 51.5769C26.6621 51.5827 26.6682 51.5891 26.6742 51.5952C26.6763 51.5975 26.6786 51.5987 26.6806 51.6009C27.3608 52.2718 28.1007 52.8924 28.8974 53.4535C30.9823 54.9226 33.3039 55.8937 35.7972 56.3411C36.5537 56.477 37.2763 55.9734 37.412 55.2173C37.5477 54.4607 37.0445 53.7374 36.2882 53.6019Z" fill="white"/>
      <path d="M38.0096 28.0652C37.3873 28.0652 36.8833 28.5697 36.8833 29.191V40.4078L47.1419 45.7109C47.307 45.7963 47.4838 45.8366 47.6577 45.8366C48.0652 45.8366 48.4587 45.6147 48.6588 45.2276C48.9442 44.675 48.7282 43.9962 48.1757 43.7109L39.1346 39.0367V29.191C39.1346 28.5697 38.6311 28.0652 38.0096 28.0652Z" fill="white"/>
    </svg>
  )
}
