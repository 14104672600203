import styled from "styled-components";

export const SecondStrikeContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    height: 380px;
    position: relative;
    justify-content: flex-start !important;
    padding: 70px 27px 40px;

    img {
        position: absolute;
        top: -95px;
    }

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #d50632;
        text-transform: uppercase;
        white-space: break-spaces;
    }

    p {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        color: #000000;
        max-width: 260px;
        margin: 20px 0 40px;

        b {
            font-weight: 700;
        }
    }

    button {
        width: 100%;
        max-width: 273px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        text-align: center;

        &.primary {
            color: #fff;
            font-weight: 700;
            background-color: #57b847;
            border-radius: 100px;
            text-transform: uppercase;
            margin: 0 0 20px;
            height: 40px;
        }
    }
`;
