import { ButtonHTMLAttributes } from 'react'
import { BtnContainer } from './styles'

interface SimpleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "primary" | "secondary"
}

export const SimpleButton = ({children, theme = "primary", ...rest}: SimpleButtonProps) => {
  return (
    <BtnContainer $theme={theme} {...rest}>{children}</BtnContainer>
  )
}
