import { ModalConfig } from "../config";
import ModalRight from "../../ModalRight";
import { AlterarSenha } from "../../AlterarSenha";

export function ModalAlterarSenha(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalRight open={open} handleClose={handleClose}>
            <AlterarSenha />
        </ModalRight>
    );
}
