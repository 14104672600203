import Table, { Pagination } from "../../Table";
import ModalPopup from "../../../NovoModal/popup";
import Modal from "../../../NovoModal";

import { Loader } from "../../../Loader";
import {
    ModalAction,
    useWhiteList,
} from "../../../../Hook/system/useWhiteList";

import { Data } from "../config";

import imgEditar from "../../../../assets/img/editar.svg";
import imgCameraVerde from "../../../../assets/img/camera-verde.svg";
import imgCheckuth from "../../../../assets/img/check-white-list-verde.svg";
import imgReprovar from "../../../../assets/img/reprovar-white-list.svg";
import imgLixeira from "../../../../assets/img/lixeira-white-list.svg";

import { formatDateSystem, formataCPF } from "../../../../util/helper";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { VerSelfie } from "./VerSelfie";
import { AprovarDoc } from "./AprovarDoc";
import { Reprovar } from "./Reprovar";

export function TableAprovar() {
    const {
        loading,
        whiteLists,
        pagination,
        getData,
        setVerSelfie,
        verSelfie,
        aprovarDoc,
        setAprovarDoc,
        reprovarDoc,
        setReprovarDoc,
        setExcluirItem,
    } = useWhiteList();

    const rows: Data[] = whiteLists?.map((list) => {
        const file = JSON.parse(list?.file || "{}") as any;
        return {
            id: list.id + "",
            approved_user: list?.approved_user?.name || "",
            reproved_user: list?.reproved_user?.name || "",
            cpf: list.document ? formataCPF(list.document) : "",
            nome: list?.name || "",
            data_inclusao: formatDateSystem(list.created_at),
            incluido_por: list?.user?.name || "",
            ver_documento: file?.document || "",
            selfie: file?.selfie || "",
            excluir: "",
            responsavel: "-",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },

        {
            field: "cpf",
            headerName: "CPF",
            width: 150,
        },

        {
            field: "nome",
            headerName: "NOME",
            width: 120,
        },

        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
            width: 150,
        },

        {
            field: "ver_documento",
            headerName: "VER DOCUMENTO",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.ver_documento) return <></>;

                return (
                    <button
                        onClick={() => window.open(row.ver_documento, "_blank")}
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },

        {
            field: "selfie",
            headerName: "VER SELFIE",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.selfie) return <></>;

                return (
                    <button
                        onClick={() =>
                            setVerSelfie({
                                active: true,
                                id: row.id,
                                image: row.selfie,
                            })
                        }
                    >
                        <img src={imgCameraVerde} alt="" />
                    </button>
                );
            },
        },

        {
            field: "aprovar",
            headerName: "APROVAR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setAprovarDoc({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgCheckuth} alt="" />
                    </button>
                );
            },
        },

        {
            field: "reprovar",
            headerName: "REPROVAR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setReprovarDoc({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgReprovar} alt="" />
                    </button>
                );
            },
        },

        {
            field: "excluir",
            headerName: "EXCLUIR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setExcluirItem({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgLixeira} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                />
            )}

            {pagination?.last_page > 1 ? (
                <Pagination
                    total={pagination?.last_page}
                    currentPage={async (page) => {
                        await getData(page);
                    }}
                />
            ) : null}

            <ModalPopup
                open={verSelfie.active}
                handleClose={() => setVerSelfie({} as ModalAction)}
            >
                <VerSelfie />
            </ModalPopup>

            <Modal
                open={aprovarDoc.active}
                handleClose={() => setAprovarDoc({} as ModalAction)}
            >
                <AprovarDoc />
            </Modal>

            <Modal
                open={reprovarDoc.active}
                handleClose={() => setReprovarDoc({} as ModalAction)}
            >
                <Reprovar />
            </Modal>
        </>
    );
}
