import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 16px;
        margin-top: 44px;
    }
`;
