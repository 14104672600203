import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 60px;
    /* min-height: 559px; */
    /* min-height: 657px; */
    .container-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99px;
        border-bottom: 1px solid #e1e2e3;
        margin: 0 5%;
    }
    /* .container-tabs {
        form {
            input {
                text-transform: unset;
            }
        }
    } */

    .container-tabs {
        width: 90%;
        margin: 0 auto;
    }
`;

export const FormStyles = styled.div`
    padding-bottom: 32px;
    p {
        text-align: center;
        margin-bottom: 26px;
    }

    form {
        height: 231px;
        display: flex;
        flex-direction: column;
        ul {
            margin-bottom: 0px;
        }
        .mt-auto {
            margin-top: 16px;
            button {
                margin-top: 0px;
            }
        }
    }
`;

export const SucessoStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* height: 305px; */
    img {
        margin-top: -41px;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 26px;
        margin-top: -30px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 135px;
    }
`;
