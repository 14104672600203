import { Container } from "./styles";
import { Popup } from "../../../../NovoModal/styles";
import { useWhiteList } from "../../../../../Hook/system/useWhiteList";
export function VerSelfie() {
    const { verSelfie } = useWhiteList();

    return (
        <Container>
            <Popup>
                <div className="container-img">
                    <img src={verSelfie.image} alt="" />
                </div>
            </Popup>
        </Container>
    );
}
