import React, { createContext, useContext, useState } from "react";
import { api_without_interceptors } from "../../services";
import { sleep } from "../../util/helper";
import { UseModal } from "../Usemodal";
import { UseLogin } from "./UseLogin";

interface UseIdWallProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

type IdWallUser = {
    data_nascimento: string;
    document: string;
    email: string;
    name: string;
    phone_number: string;
    token: string;
};

interface UseIdWallData {
    idWallUser: IdWallUser;
    setIdWallUser: (data: IdWallUser) => void;
    checkIdWallDocStage: () => void;

    count: number;
}

export type IStage =
    | "NOT_FOUND"
    | "WAITING"
    | "DOCUMENT"
    | "DOCUMENT_SENTED"
    | "WHITELIST"
    | "REPROVED"
    | "FINISHED";

const IdWallContext = createContext<UseIdWallData>({} as UseIdWallData);

export function IdWallProvider({ children }: UseIdWallProviderProps) {
    const { handleOpen, state, setState } = UseModal();
    const { setModal } = UseLogin();
    const [count, setCount] = useState(0);
    const [idWallUser, setIdWallUser] = useState({} as IdWallUser);

    async function checkIdWallDocStage(count = 0) {
        if (count === 36) {
            setState({
                ...state,
                validandoDadosIdWall: false,
                tempoExpirado: true,
            });
            return;
        }

        const { data } = await api_without_interceptors.post<{ stage: IStage }>(
            "/consult-idwall-stage",
            { document: state.dados.document }
        );

        if (data.stage === "FINISHED" || data.stage === "WHITELIST") {
            setModal(true);
        }

        if (data.stage === "WAITING" || data.stage === "NOT_FOUND") {
            await sleep(5000);
            setCount(count + 1);
            await checkIdWallDocStage(count + 1);
        }

        if (data.stage === "REPROVED") {
            handleOpen("acessoBloqueadoIdWall");
        }

        if (data.stage === "DOCUMENT") {
            handleOpen("verificacaoDocumentoIdWall", {
                document: state.dados.document,
            });
        }

        if (data.stage === "DOCUMENT_SENTED") {
            handleOpen("documentoEmAnaliseIdWall");
        }
    }

    return (
        <IdWallContext.Provider
            value={{
                idWallUser,
                setIdWallUser,
                checkIdWallDocStage,
                count,
            }}
        >
            {children}
        </IdWallContext.Provider>
    );
}

export function useIdWall() {
    const context = useContext(IdWallContext);

    return context;
}
