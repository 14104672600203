import { useEffect } from "react";
import { Box } from "alisson-application";
import { HeaderPassos } from "../HeaderPassos";
import { Validadar } from "../Validar";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { Documento } from "./documento";
import { AltearSenha } from "./alterarSenha";
import { Sucesso } from "./sucesso";
import { Container } from "./styles";
import { Email } from "./email";
import imgLogo from "../../../assets/img/logo-menu.svg";
import { useHistory } from "react-router-dom";
import { When } from "../../when";
export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
];

export function EsqueciSenha() {
    const { setPassos, passos, nextPasso, dados } = usePassos();
    const { state } = UseModal();
    const history = useHistory();

    useEffect(() => {
        if (state?.esqueciSenha) {
            setPassos(arrPassos);
        }
    }, [setPassos, state?.esqueciSenha]);

    async function validarUsuario() {
        nextPasso(3);
    }

    return (
        <Container>
            <div className="container-menu">
                <img src={imgLogo} alt="" />
            </div>
            <HeaderPassos handleClose={() => {}} title="RECUPERAR SENHA" />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    <When expr={history.location.pathname === "/sistema"}>
                        <>{passos[0]?.active ? <Email /> : null}</>
                    </When>

                    <When expr={history.location.pathname !== "/sistema"}>
                        <>{passos[0]?.active ? <Documento /> : null}</>
                    </When>
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        <Validadar
                            handleClick={validarUsuario}
                            phone_number={dados.phone_number}
                            autoSend={false}
                        />
                    ) : null}
                </div>
                <Box
                    pb="96px"
                    className={`tab ${passos[2]?.active ? "active" : ""}`}
                >
                    {passos[2]?.active ? <AltearSenha /> : null}
                </Box>
                <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                    <Sucesso />
                </div>
            </div>
        </Container>
    );
}
