import { Tabs } from "./tabs";
import Modal from "../../NovoModal";
import { Content } from "./content";

import { Header } from "./header";
import { ExcluirDoc } from "./content/ExcluirDoc";
import { ModalAction, useWhiteList } from "../../../Hook/system/useWhiteList";

export function WhiteList() {
    const { excluirItem, setExcluirItem } = useWhiteList();

    return (
        <>
            <Header />

            <Tabs />

            <Content />

            <Modal
                open={excluirItem.active}
                handleClose={() => setExcluirItem({} as ModalAction)}
            >
                <ExcluirDoc />
            </Modal>
        </>
    );
}
