import styled from "styled-components";

export const PasswordStepContainer = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 60px 30px;
`;

export const PasswordContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 330px;
`;

export const Title = styled.h1`
    font-family: Oxanium;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    text-align: center;
    color: #57b847;

    text-transform: uppercase;
    text-align: center;
`;

export const Description = styled.p`
    font-family: Oxanium;
    font-size: 14px;
    font-weight: 400;

    color: #000;

    margin: 20px 0 30px;
    text-align: center;

    b {
        text-transform: uppercase;
    }
`;

export const CustomBtn = styled.button`
    width: 100%;
    height: 40px;
    max-width: 327px;
    text-align: center;

    font-family: Oxanium;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    color: #fff;
    background: #57b847;
    cursor: pointer;
    border-radius: 100px;

    transition: all 0.4s;

    &:hover {
        transform: scale(1.1);
    }
`;

export const DisabledInput = styled.input`
    max-width: 327px;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-radius: 100px;
    background: #e3e3e3;
    color: #5e5f5f;
    border: none;

    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    text-align: center;

    margin-bottom: 10px;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }
`;
