import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 32px;
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 95px;
        border-bottom: 1px solid #e1e2e3;
        width: 90%;
        margin: 0 auto;
    }

    .container-check {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
    }

    .container-text {
        margin-bottom: 72px;
        h3 {
            font-family: "Oxanium";
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: ${({ theme }) => theme.colors.secondary};
            text-transform: uppercase;
        }
    }

    .container-btn {
        width: 90%;
        margin: 0 auto;
    }
`;
