import * as Yup from "yup";
import { regexPassword } from "../EsqueciSenha/config";
// const regexValidadeCPf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

// const regex =
//     /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const date = /\d{2}\/\d{2}\/\d{4}/;

export const isValidBRZipRegex = /^[0-9]{5}-[0-9]{3}$/;

// const regexCheckTwoWorks = /^(.*) (.*).*/;
export interface Documento {
    document: string;
}

export const documento: Documento = {
    document: "",
};

export const validationDocumento = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
});

export interface Password {
    password: string;
    document: string;
}

export const password: Password = {
    password: "",
    document: "",
};

export const validationPassword = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
    password: Yup.string()
        .min(6, "Senha deve ter 6 números")
        .required("Senha é obrigatório"),
});

export interface CampanhaForm {
    campanha: string;
}

export const campanhaForm: CampanhaForm = {
    campanha: "",
};

export const validationCampanhaForm = Yup.object().shape({
    campanha: Yup.string().required("Campo obrigatório"),
});

export interface FirstStep {
    document: string;
    campanha: string;
    password: string;
}

export const firstStep: FirstStep = {
    document: "",
    campanha: "",
    password: "",
};

export const validationFirsStep = Yup.object().shape({
    document: Yup.string().required("E-mail e um campo obrigatório"),
    campanha: Yup.string(),
    password: Yup.string(),
});

export interface Register {
    campanha: string;
    document: string;
    name: string;
    apelido: string;
    genero: string;
    data_nascimento: string;
    phone_number: string;
    confirm_phone_number: string;
    email: string;
    email_confirmation: string;
    password: string;
    password_confirmation: string;
}

export const register: Register = {
    name: "",
    document: "",
    apelido: "",
    data_nascimento: "",
    genero: "",
    email: "",
    email_confirmation: "",
    phone_number: "",
    confirm_phone_number: "",
    password: "",
    password_confirmation: "",
    campanha: "",
};
//37701086
export const validationRegister = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
    campanha: Yup.string(),
    name: Yup.string()
        .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),
    apelido: Yup.string().required("Campo obrigatório"),
    genero: Yup.string().required("Campo obrigatório"),
    data_nascimento: Yup.string()
        .matches(date, "DIGITE A DATA CORRETAMENTE")
        .required("Campo obrigatório"),
    phone_number: Yup.string().required("Celular é obrigatório"),
    confirm_phone_number: Yup.string().oneOf(
        [Yup.ref("phone_number"), null],
        "Celular não confere"
    ),

    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    email_confirmation: Yup.string().oneOf(
        [Yup.ref("email"), null],
        "E-mail não confere"
    ),
    password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Senha é obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),
});

export interface IEndereco {
    logradouro: string;
    cep: string;
    nome: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    pais: string;
    aceita_sms: boolean;
    aceita_whats: boolean;
    aceita_email: boolean;
    aceita_termos: boolean;
    aceita_politica_uso_de_dados: boolean;
    aceita_politicamente_exposta: boolean;
}

export const endereco: IEndereco = {
    logradouro: "",
    cep: "",
    nome: "rua",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "Brasil",
    aceita_email: false,
    aceita_sms: true,
    aceita_whats: true,
    aceita_termos: false,
    aceita_politica_uso_de_dados: false,
    aceita_politicamente_exposta: false,
};

export const validationEndereco = Yup.object().shape({
    logradouro: Yup.string().required("Campo obrigatório"),
    cep: Yup.string()
        .matches(isValidBRZipRegex, "Digite um CEP valido")
        .required("Campo obrigatório"),
    nome: Yup.string().required("Campo obrigatório"),
    numero: Yup.string().required("Campo obrigatório"),
    bairro: Yup.string().required("Campo obrigatório"),
    cidade: Yup.string().required("Campo obrigatório"),
    estado: Yup.string().required("Campo obrigatório"),
    pais: Yup.string().required("Campo obrigatório"),
    aceita_email: Yup.bool(),
    aceita_sms: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_whats: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_termos: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_politicamente_exposta: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_politica_uso_de_dados: Yup.bool().oneOf([true], "Campo obrigatório"),
});

export const validationSelectCampanha = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
    selectCampanha: Yup.string().required("Senha é obrigatório"),
});

export interface CampanhaSelect {
    document: string;
    selectCampanha: string;
}

export const campanhaSelectForm: CampanhaSelect = {
    document: "",
    selectCampanha: "",
};

export interface CartaoAvulso {
    account: string;
}

export const cartao: CartaoAvulso = {
    account: "",
};

export const validationCartao = Yup.object().shape({
    account: Yup.string()
        .max(8, "Número da conta deve ter 8 números")
        .required("Campo obrigatório"),
});

export interface RegisterCNPJ {
    campanha: string;
    apelido: string;
    document: string;
    name: string;
    phone_number: string;
    confirm_phone_number: string;
    email: string;
    email_confirmation: string;
    password: string;
    password_confirmation: string;

    aceita_sms: boolean;
    aceita_whats: boolean;
    aceita_email: boolean;
    aceita_termos: boolean;
    aceita_politica_uso_de_dados: boolean;
    aceita_politicamente_exposta: boolean;
}

export const registerCNPJ: RegisterCNPJ = {
    campanha: "",
    apelido: "",
    document: "",
    name: "",
    phone_number: "",
    confirm_phone_number: "",
    email: "",
    email_confirmation: "",
    password: "",
    password_confirmation: "",

    aceita_email: false,
    aceita_sms: true,
    aceita_whats: true,
    aceita_termos: false,
    aceita_politica_uso_de_dados: false,
    aceita_politicamente_exposta: false,
};

export const validationRegisterCNPJ = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
    apelido: Yup.string().required("Campo obrigatório"),
    campanha: Yup.string(),
    name: Yup.string()
        // .matches(regexCheckTwoWorks, "DIGITE O NOME COMPLETO")
        // .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),

    phone_number: Yup.string().required("Celular é obrigatório"),
    confirm_phone_number: Yup.string().oneOf(
        [Yup.ref("phone_number"), null],
        "Celular não confere"
    ),

    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    email_confirmation: Yup.string().oneOf(
        [Yup.ref("email"), null],
        "E-mail não confere"
    ),
    password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Senha é obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),

    aceita_email: Yup.bool(),
    aceita_sms: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_whats: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_termos: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_politicamente_exposta: Yup.bool().oneOf([true], "Campo obrigatório"),
    aceita_politica_uso_de_dados: Yup.bool().oneOf([true], "Campo obrigatório"),
});
