import styled from "styled-components";
import { Button } from "../Mobile/Button";
export const Container = styled.div`
    padding-top: 60px;
    padding-bottom: 41px;
    width: 90%;
    margin: 0 auto;
    .container-img {
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
    }

    .container-title {
        margin-bottom: 16px;
        h3 {
            text-align: center;
            font-size: 20px;
            color: #d50632;
            text-transform: uppercase;
        }
    }

    .container-text {
        margin: 0 auto;
        margin-bottom: 32px;
        p {
            color: #5e5f5f;
            font-size: 13px;
            text-align: center;
            strong {
            }
        }
    }

    .container-btns {
        ${Button} {
            background-color: #57b847;
            margin-bottom: 8px;
            height: 40px;
        }
        span {
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            display: block;
            margin-left: 8px;
        }
    }
`;
