import Table, { Pagination } from "../Table";
import { useHistory } from "react-router-dom";
import { Data } from "./config";
import { Bloquear } from "./bloquear";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Loader } from "../../Loader";
import { Text } from "alisson-application";
import userImg from "../../../assets/img/user.png";
import { Card } from "../../../Hook/system/useCartao";
import { PaginationConfig } from "../../../Hook/system/config";
import {
    formatDateIsoSystem,
    tipoCartao,
    formatCurrentMoney,
} from "../../../util/helper";
import { Saldo } from "../Usuarios/detalhe/saldo";
import { useState } from "react";
import Modal from "../../NovoModal";
import { Entrega } from "./entrega";
import imgEntrega from "../../../assets/img/entrega.svg";

interface TableUserProps {
    loading: boolean;
    cards: Card[];
    config: PaginationConfig;
    search: string;
    pagination: (page: number, search: string) => Promise<void>;
}

export function TableUser(props: TableUserProps) {
    const { loading, cards, config, pagination, search } = props;
    const width = 1645 / 7;
    const [modalEntrega, setModalEntrega] = useState(false);
    const [id, setId] = useState<null | number>();
    const history = useHistory();

    const dados: Data[] = cards?.map((item) => {
        return {
            id: item?.id + "",
            conta: item?.acg_account_id || "",
            tipo: tipoCartao(item?.produto?.tipo || ""),
            data_inclusao: formatDateIsoSystem(item.created_at),
            data_solicitacao: formatDateIsoSystem(item.requested_at),
            produto: item?.produto?.nome || "",
            status: item?.status || "",
            cartao: item.acg_account_card_last4digits,
            usuarioVinculado: `${item?.user?.document || ""} ${
                item?.user?.name || ""
            }`,
            first_active: item.first_active,
            data_status:
                item?.logs[0] || ""
                    ? formatDateIsoSystem(item?.logs[0]?.updated_at || "")
                    : "",
            image: item?.user?.image || userImg,
            campanha: item?.campanha ? item?.campanha : "",
            entrega: "",
            cobranca: `R$ ${
                item?.cobranca
                    ? formatCurrentMoney(0)
                    : formatCurrentMoney(
                          parseFloat(item?.cobranca?.valor + "") || 0
                      )
            }`,
            entrega_tipo: item?.tipo,
            acg_tracking_id: item?.acg_tracking_id || "-",
            user_id: item?.user?.id,
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "id",
            width: 30,
        },

        {
            field: "conta",
            headerName: "Conta",
            width: 150,
        },
        {
            field: "cartao",
            headerName: "Últimos 4 Números",
            width: 150,
        },

        {
            field: "tipo",
            headerName: "TIPO",
            width: 150,
        },
        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
            width: 180,
        },
        {
            field: "data_solicitacao",
            headerName: "DATA DE SOLICITAÇÃO",
            width: 180,
        },
        {
            field: "produto",
            headerName: "PRODUTO",
            width: 300,
        },
        {
            field: "acg_tracking_id",
            headerName: "Tracking_ID",
            width: 300,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Bloquear
                        status={row.status}
                        id={parseInt(row.id)}
                        disabled={!row.first_active ? true : false}
                    />
                );
                // return (

                //     <Button
                //         className={`cadastro ${
                //             row.status === "VINCULADO" ? "" : "ERROR"
                //         }`}
                //     >
                //         {row.status}
                //     </Button>
                // );
            },
            width: 200,
        },
        {
            field: "usuarioVinculado",
            headerName: "USUÁRIOS VINCULADOS",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.usuarioVinculado.trim()) return <></>;
                return (
                    <Text
                        className="user"
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/usuario/${row.user_id}`,
                                state: {
                                    id: row.user_id,
                                    campanhaId: row.campanha_id,
                                },
                            });
                        }}
                    >
                        <img src={row.image} alt="" />
                        <p>{row.usuarioVinculado}</p>
                    </Text>
                );
            },
            width: 400,
        },
        {
            field: "campanha",
            headerName: "CAMPANHA",
            width: 180,
        },
        {
            field: "entrega",
            headerName: "ENTREGA",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (row.entrega_tipo === "cartao_fisico") {
                    return (
                        <button
                            onClick={async () => {
                                setModalEntrega(true);
                                setId(row.id);
                            }}
                        >
                            <img src={imgEntrega} alt="" />
                        </button>
                    );
                }

                return <></>;
            },
            width: 100,
        },
        {
            field: "cobranca",
            headerName: "Cobrança Taxa",
            width,
        },
        {
            field: "saldo",
            headerName: "VER SALDO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Saldo id={row.id} />;
            },
            width: 100,
        },
        {
            field: "data_status",
            headerName: "DATA STATUS",
            width,
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    rows={dados ? dados : []}
                    columns={columns}
                    nameCSV="USUÁRIOS"
                />
            )}

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page, search);
                    }}
                />
            ) : null}

            <Modal
                open={modalEntrega}
                handleClose={() => setModalEntrega(false)}
            >
                {id ? <Entrega id={id} /> : null}
            </Modal>
        </>
    );
}
