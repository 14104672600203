import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    .container-loader {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 291px;
        height: 7px;
        background-color: #f5f5f5;
        border-radius: 5px;
        position: relative;
        .loader {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0px;
            height: 100%;
            background-color: #57b847;
            border-radius: 5px;
            transition: all 5s;
        }
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 0 0 25px;
    border-bottom: 1px solid #ccc;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 90px 0 0;
    width: 100%;

    h1 {
        color: #000;
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 30px 0 20px;
    }

    p {
        color: #000;
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        max-width: 242px;
    }

    button {
        margin: 60px 0 0;

        & + button {
            margin: 10px 0 0;
        }
    }
`;
