import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { Button } from "../Button";
import imgSuccess from "../../../assets/img/success-user-side.svg";
import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";
import { toast } from "react-toastify";
export function AlertaBoleto() {
    const width = window.screen.width;
    const { cartao } = UseCartoes();

    const { handleClose, state, setState } = UseModal();
    const history = useHistory();

    function handleAcceptModal() {
        const updatePassword =
            cartao?.first_update_password === 0 &&
            cartao.tipo === "cartao_virtual";

        if (width <= 480) {
            if (updatePassword) {
                handleClose("alertaBoleto");
                history.push("/alterar-senha-cartao");
                toast.error(
                    "Você precisa cadastrar sua senha antes de continuar"
                );
                return;
            }
            handleClose("alertaBoleto");
            history.push({
                pathname: "/pagamento-boleto",
                state: {
                    valid: true,
                },
            });
        } else {
            if (updatePassword) {
                toast.error(
                    "Você precisa cadastrar sua senha antes de continuar"
                );
                setState({
                    ...state,
                    alertaBoleto: false,
                    atualizaSenha: true,
                });
                return;
            }
            setState({
                ...state,
                alertaBoleto: false,
                boleto: true,
            });
        }
    }
    //2379338128 60088207521 39000063303 1 94800000000600
    return (
        <Container>
            <div className="container">
                <img className="img-close" src={imgSuccess} alt="" />

                <Text as="p" textAlign="center" mb="43px">
                    Para pagamentos de contas há um custo de R$3,50 por
                    pagamento efetuado, debitado diretamente do saldo do seu
                    cartão. Você concorda com essa cobrança?
                </Text>

                <Button onClick={() => handleAcceptModal()}>
                    CONFIRMAR COBRANÇA
                </Button>

                <button
                    className="cancelar"
                    onClick={() => handleClose("alertaBoleto")}
                >
                    NÃO, CANCELAR
                </button>
            </div>
        </Container>
    );
}
