import styled from "styled-components";

export const Container = styled.div`
    padding-top: 52px;
    padding-bottom: 126px;
    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 66px;
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-top: 14px;
        margin-bottom: 60px;
        strong {
            font-weight: bold;
        }
    }

    button {
        margin-bottom: 6px;
        img {
            margin-right: 6px;
        }
    }
`;
