import styled from "styled-components";

export const FirstStrikeContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    height: 340px;
    position: relative;
    justify-content: flex-start !important;
    padding: 90px 27px 40px;

    img {
        position: absolute;
        top: -95px;
    }

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #d50632;
        text-transform: uppercase;
    }

    p {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        color: #000000;
        margin: 20px 0 60px;
    }

    button {
        height: 40px;
        width: 100%;
        max-width: 273px;
        border-radius: 100px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        background-color: #57b847;
    }
`;
