import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 48px;
    padding-top: 84px;
    position: relative;

    form {
        width: 327px;
        button[type="submit"] {
            margin-top: 22px;
        }
    }
    img {
        &.img-close {
            position: absolute;
            top: -87px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
        }
    }

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.failure};
        margin-bottom: 26px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        width: 100%;
        margin-bottom: 26px;
    }

    .container-input {
        width: 100%;
        &.olho {
            button {
                height: 28px;
            }
        }
    }
`;
