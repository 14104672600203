import { useHistory } from "react-router-dom";

import LogoImg from "../../assets/img/logo-menu.svg";
import { ModalErrorIcon } from "../../assets/img/ModalErrorIcon";
import { UseModal } from "../../Hook/Usemodal";
import { SimpleButton } from "../SimpleButton";

import { Container, LogoContainer, ModalContent } from "./styles";

export function AcessoBloqueadoIdWall() {
    const { handleClose } = UseModal();
    const history = useHistory();

    function handleNavigateToFaleConosco() {
        handleClose("acessoBloqueadoIdWall");
        history.push("/precisa-ajuda");
    }

    function handleCancel() {
        handleClose("acessoBloqueadoIdWall");
        history.push("/");
    }

    return (
        <Container>
            <LogoContainer>
                <img src={LogoImg} alt="logo" />
            </LogoContainer>

            <ModalContent>
                <ModalErrorIcon />

                <h1>Dados Inconsistentes</h1>

                <p>Infelizmente não foi possível concluir seu cadastro. Entre em contato com o nosso Fale Conosco.</p>

                <SimpleButton onClick={handleNavigateToFaleConosco}>
                    Fale Conosco
                </SimpleButton>

                <SimpleButton theme="secondary" onClick={handleCancel}>
                    Cancelar
                </SimpleButton>
            </ModalContent>
        </Container>
    );
}
