import { Text } from "alisson-application";
import { ChangeEvent, useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { motion } from "framer-motion";
import { rangeDate, maskCPF_CNPJ } from "../../../util/helper";

import { UseLogin } from "../../../Hook/user/UseLogin";

import { Button } from "../Button";
import { documento, Documento, validationDocumento } from "./config";
import { UseModal } from "../../../Hook/Usemodal";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
// import { toast } from "react-toastify";

export function FormDocumento() {
    const { checkDocument } = UseLogin();
    const {
        handleOpen,
        setState: modalSetState,
        state: modalState,
    } = UseModal();
    const { controllerForm, setControllerForm, setState, state } =
        useFormLogin();
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            enableReinitialize
            initialValues={documento}
            validationSchema={validationDocumento}
            validateOnMount
            onSubmit={async (values, actions) => {
                setLoading(true);
                try {
                    const document = values.document.replace(/[^\d]+/g, "");

                    const response = await checkDocument({
                        tipo: document.length <= 11 ? "fisica" : "juridica",
                        document: document,
                        campanha: state.campanha,
                    });

                    const { data } = response;
                    const start = data.campanha?.start_date_time;
                    const end = data.campanha?.end_date_time;

                    if (data.user?.status === 0) {
                        handleOpen("acessoBloqueado", {
                            message: ``,
                            layout: 2,
                        });
                        setLoading(false);
                        return;
                    }

                    // data = {
                    //     ...data,
                    //     stage: "DOCUMENT_SENTED",
                    // };

                    const arrExclude = ["FINISHED", "WHITELIST"];
                    if (data?.stage && !arrExclude.includes(data?.stage)) {
                        if (data?.stage === "DOCUMENT") {
                            handleOpen("verificacaoDocumentoIdWall", {
                                document,
                            });
                        }
                        if (data?.stage === "DOCUMENT_SENTED") {
                            handleOpen("documentoEmAnaliseIdWall", {
                                login: true,
                            });
                        }
                        if (data?.stage === "REPROVED") {
                            handleOpen("acessoBloqueadoIdWall");
                        }
                        setLoading(false);
                        return;
                    }

                    // força conta no-name
                    if (data.campanha.force_account_register === 1) {
                        if (
                            data.user?.register_status === 1 &&
                            data?.user_on_campanha === 1
                        ) {
                            setControllerForm({
                                ...controllerForm,
                                documento: false,
                                password: true,
                            });
                            setState({
                                ...state,
                                document: document,
                            });
                        } else {
                            setControllerForm({
                                ...controllerForm,
                                documento: false,
                                campanha: false,
                                completeRegister: true,
                                // cartaoNoname: false,
                            });

                            setState({
                                ...state,
                                document: document,
                            });
                        }
                        return;
                    }

                    // item A
                    if (
                        data.user?.register_status === 0 &&
                        data.user_on_campanha === 1
                    ) {
                        setControllerForm({
                            ...controllerForm,
                            documento: false,
                            campanha: false,
                            completeRegister: true,
                        });

                        setState({
                            ...state,
                            name: data?.user?.name,
                            document: document,
                        });
                        return;
                    }

                    // item B
                    if (
                        data.user?.register_status === 1 &&
                        data?.user_on_campanha === 1
                    ) {
                        setControllerForm({
                            ...controllerForm,
                            documento: false,
                            password: true,
                        });
                        setState({
                            ...state,
                            document: document,
                        });
                        return;
                    }

                    if (
                        data.user?.register_status === 0 &&
                        data.campanha.force_account_register === 0
                    ) {
                        setControllerForm({
                            ...controllerForm,
                            documento: false,
                            campanha: false,
                            completeRegister: true,
                        });

                        setState({
                            ...state,
                            document: document,
                        });
                        return;
                    }

                    //item C
                    if (
                        data.campanha?.external_register === 1 &&
                        data.user === null &&
                        rangeDate(start, end)
                    ) {
                        setControllerForm({
                            ...controllerForm,
                            documento: false,
                            campanha: false,
                            completeRegister: true,
                            // cartaoNoname: true,
                        });

                        setState({
                            ...state,
                            campanha: state.campanha,
                            document: document,
                        });
                        return;
                    }

                    // item D
                    if (
                        data?.user_on_campanha === 0 &&
                        data.campanha.external_register === 1 &&
                        data.user?.register_status === 0 &&
                        rangeDate(start, end)
                    ) {
                        setControllerForm({
                            ...controllerForm,
                            documento: false,
                            campanha: false,
                            completeRegister: true,
                            // cartaoNoname: true,
                        });

                        setState({
                            ...state,
                            campanha: state.campanha,
                            document: document,
                        });
                        return;
                    }

                    if (
                        data?.user_on_campanha === 0 &&
                        data.campanha.external_register === 1 &&
                        data.user?.register_status === 1 &&
                        data.campanha.force_account_register === 0 &&
                        rangeDate(start, end)
                    ) {
                        handleOpen("vincularUsuarioCampanha");
                        setLoading(false);
                        setState({
                            ...state,
                            campanha: state.campanha,
                            document: document,
                        });
                        return;
                    }

                    setControllerForm({
                        campanha: true,
                        documento: false,
                        cartaoNoname: false,
                        selectCampanha: false,
                        completeRegister: false,
                        password: false,
                        endereco: false,
                    });

                    modalSetState({
                        ...modalState,
                        errorGeral: {
                            active: true,
                            title: "Erro",
                            message:
                                "Usuário não encontrado, verifique os dados digitados ou procure quem lhe premiou.",
                        },
                    });
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
                setLoading(false);
            }}
        >
            {(props: FormikProps<Documento>) => {
                const { values, setFieldValue } = props;
                const { document } = values;

                return (
                    <Form autoComplete="on">
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.6,
                            }}
                            className={`container-input chave chave-campanha active disabled`}
                        >
                            <Field
                                placeholder="Campanha"
                                name="campanha"
                                type="text"
                                autoComplete="username"
                                value={state.campanha}
                            />
                        </motion.div>
                        <ErrorMessage name="campanha" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input user `}
                        >
                            <Field
                                placeholder="CPF/CNPJ"
                                name="document"
                                type="tel"
                                autoComplete="organization"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    const document = maskCPF_CNPJ(
                                        e.target.value
                                    );

                                    if (document.length <= 18) {
                                        setFieldValue("document", document);
                                    }
                                }}
                                value={document}
                            />
                        </motion.div>
                        <button
                            type="button"
                            onClick={() => handleOpen("esqueciSenha")}
                        >
                            <Text color="white" mb="32px" textAlign="center">
                                Esqueci minha senha
                            </Text>
                        </button>
                        <ErrorMessage name="documento" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                className="login"
                            >
                                ENTRAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
