/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

import LogoImg from "../../assets/img/logo-menu.svg";
import { TimeIcon } from "../../assets/img/TimeIcon";
import { useIdWall } from "../../Hook/user/useIdWall";

import { Container, LogoContainer, ModalContent } from "./styles";

export function ValidandoDadosIdWall() {
    const { checkIdWallDocStage, count } = useIdWall();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        checkIdWallDocStage();
    }, []);

    function calcularPorcentagem(x: number) {
        const valorInicial = 0;
        const valorFinal = 36;

        // Garantir que o número esteja dentro do intervalo
        if (x < valorInicial) x = valorInicial;
        if (x > valorFinal) x = valorFinal;

        // Calcular a porcentagem
        const porcentagem =
            ((x - valorInicial) / (valorFinal - valorInicial)) * 100;

        return porcentagem.toFixed(2);
    }

    useEffect(() => {
        if (!ref.current) return;
        const loader = ref.current;

        function startLoader(percent: string) {
            loader.style.width = `${percent}%`;
        }
        startLoader(calcularPorcentagem(count));
    }, [count]);

    return (
        <Container>
            <LogoContainer>
                <img src={LogoImg} alt="logo" />
            </LogoContainer>

            <ModalContent>
                <TimeIcon />

                <h1>Aguarde</h1>

                <p>
                    Em alguns segundos prosseguiremos com a validação de seu
                    cadastro.
                </p>

                <div className="container-loader">
                    <div className="loader" ref={ref} />
                </div>
            </ModalContent>
        </Container>
    );
}
