import { useState } from "react";
import { Text } from "alisson-application";
import { Formik, Form, FormikProps } from "formik";

import imgArquivo from "../../../assets/img/arquivo.svg";
import imgFile from "../../../assets/img/file.svg";
import { Container } from "./styles";
import { Button } from "../Button";
import { MyForm, initialValues, validation } from "./config";
import { UseModal } from "../../../Hook/Usemodal";

export function VerificacaoDocumento() {
    const [file, setFile] = useState<File | null>(null);
    const { setState, state } = UseModal();
    const [loading, setLoading] = useState(false);
    return (
        <Container>
            <div className="container-img">
                <img src={imgFile} alt="" />
            </div>
            <div className="container-text">
                <h4>Falta apenas uma etapa.</h4>
                <br />
                <p>
                    Para concluir seu cadastro é necessário <br /> realizar uma
                    verificação dos dados fornecidos.
                    <br /> Pedimos que nos envie uma cópia legível de <br />
                    seu documento (RG ou CNH).
                </p>
            </div>

            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    const formDate = new FormData();
                    if (file) {
                        formDate.append("pending_document", file);
                    }

                    try {
                        // await api.post(`usuario/${user.id}`, formDate);
                        setState({
                            ...state,
                            verificacaoDocument: false,
                            sucessoVerificacaoDocumento: true,
                        });
                    } catch (error) {
                        setState({
                            ...state,
                            verificacaoDocument: false,
                            errorDocumento: true,
                        });
                    }
                    setLoading(false);
                }}
            >
                {(props: FormikProps<MyForm>) => {
                    const valid =
                        props.errors.file ===
                        "O arquivo deve ter no máximo 25mb.";
                    return (
                        <Form>
                            <label
                                htmlFor="arquivo-id-wall"
                                className={`arquivo ${valid ? "error" : ""}`}
                            >
                                <img src={imgArquivo} alt="" />
                                <Text as="p" ml="12px">
                                    {file?.name
                                        ? file?.name
                                        : "ANEXAR DOCUMENTO"}
                                </Text>
                                <input
                                    type="file"
                                    id="arquivo-id-wall"
                                    accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.xm"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        if (e.target.files?.length) {
                                            const file = e.target.files[0];

                                            const fileSizeInMB =
                                                file.size / (1024 * 1024) + "";

                                            if (parseInt(fileSizeInMB) > 24) {
                                                props.setFieldError(
                                                    "file",
                                                    "O arquivo deve ter no máximo 25mb."
                                                );
                                                return;
                                            }
                                            props.setFieldValue("file", "file");
                                            props.setFieldError("file", "");
                                            setFile(file);
                                        }
                                    }}
                                />
                            </label>
                            {valid && (
                                <Text as="p" className="error-input">
                                    *Arquivo com tamanho maior que o permitido.
                                </Text>
                            )}
                            <Text as="small" fontSize="xss" mb="14px">
                                Arquivos aceitos: jpg, png e pdf com até 25mb.
                            </Text>
                            <Button
                                spinerColor="primary"
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                            >
                                ENVIAR
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
