import Table, { Pagination } from "../../Table";
import Modal from "../../../NovoModal";
import { Loader } from "../../../Loader";
import {
    ModalAction,
    useWhiteList,
} from "../../../../Hook/system/useWhiteList";

import { Data } from "../config";

import imgEditar from "../../../../assets/img/editar.svg";
import imgLixeira from "../../../../assets/img/lixeira-white-list.svg";
import imgMotivoReprova from "../../../../assets/img/motivo-reprova.svg";
import { formatDateSystem, formataCPF } from "../../../../util/helper";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { MotivoReprovar } from "./MotivoReprova";

export function TableWhiteList() {
    const {
        loading,
        whiteLists,
        setExcluirItem,
        pagination,
        getData,
        setMotivoReprovar,
        motivoReprovar,
        tabs,
    } = useWhiteList();

    const rows: Data[] = whiteLists?.map((list) => {
        return {
            id: list.id + "",
            approved_user: list?.approved_user?.name || "",
            reproved_user: list?.reproved_user?.name || "",
            cpf: list.document ? formataCPF(list.document) : "",
            nome: list?.name || "",
            data_inclusao: formatDateSystem(list.created_at),
            incluido_por: list?.user?.name || "",
            ver_documento: "",
            reproved_reason: list?.reproved_reason || "",
            excluir: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "cpf",
            headerName: "CPF",
        },
        {
            field: "nome",
            headerName: "NOME",
        },
        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
        },
        {
            field: "incluido_por",
            headerName: "DATA DE INCLUSÃO",
        },

        {
            field: "ver_documento",
            headerName: "VER DOCUMENTO",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.ver_documento) return <></>;

                return (
                    <button
                        onClick={() => window.open(row.ver_documento, "_blank")}
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },

        // {
        //     field: "ver_documento",
        //     headerName: "VER DOCUMENTO",
        //     width: 200,
        //     renderCell: ({ row }: GridRenderCellParams) => {
        //         return (
        //             <button
        //                 onClick={() =>
        //                     history.push({
        //                         pathname: `/sistema/white-list/editar/${row.id}`,
        //                         state: {
        //                             id: row.id,
        //                         },
        //                     })
        //                 }
        //             >
        //                 <img src={imgEditar} alt="" />
        //             </button>
        //         );
        //     },
        // },

        {
            field: "motivo_reprova",
            headerName: "MOTIVO REPROVAÇÃO",
            hide: true,
            hideable: true,

            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setMotivoReprovar({
                                active: true,
                                id: row.id,
                                text: row.reproved_reason,
                            })
                        }
                    >
                        <img src={imgMotivoReprova} alt="" />
                    </button>
                );
            },
        },

        {
            field: "approved_user",
            headerName: "VALIDADO",
        },
        {
            field: "reproved_user",
            headerName: "REPROVADO",
        },

        {
            field: "excluir",
            headerName: "EXCLUIR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setExcluirItem({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgLixeira} alt="" />
                    </button>
                );
            },
        },
    ];

    const tabAprovado =
        tabs.filter((tab) => tab.active)[0].className === "aprovados";

    if (tabAprovado) {
        const index = collumns.findIndex(
            (item) => item.field === "motivo_reprova"
        );
        collumns.splice(index, 1);
    }
    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                />
            )}

            {pagination?.last_page > 1 ? (
                <Pagination
                    total={pagination?.last_page}
                    currentPage={async (page) => {
                        await getData(page);
                    }}
                />
            ) : null}

            <Modal
                open={motivoReprovar.active}
                handleClose={() => setMotivoReprovar({} as ModalAction)}
            >
                <MotivoReprovar />
            </Modal>
        </>
    );
}
