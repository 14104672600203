import {
    useContext,
    createContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { toast } from "react-toastify";
import { User } from "./useUser";
import { HistoryProps, PaginationConfig } from "./config";

interface useWhiteListProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseWhiteList {
    store: (values: FormData) => Promise<AxiosResponse>;
    update(formDate: FormData): Promise<AxiosResponse>;
    deleteList(id: string): Promise<void>;
    getData: (page?: number) => Promise<void>;
    handleTab(id: number): void;
    aprovar(id: number): Promise<void>;
    reprovar(value: string): Promise<void>;

    id: number | null;
    setId(id: number | null): void;

    modaldialog: boolean;
    setModaldialog(value: boolean): void;

    setSearch: (value: string) => void;
    tabs: Tabs[];
    whiteLists: WhiteList[];
    whiteList: WhiteList;
    loading: boolean;
    pagination: PaginationConfig;

    setVerSelfie: (value: ModalAction) => void;
    verSelfie: ModalAction;

    setAprovarDoc: (value: ModalAction) => void;
    aprovarDoc: ModalAction;

    setReprovarDoc: (value: ModalAction) => void;
    reprovarDoc: ModalAction;

    setMotivoReprovar: (value: ModalAction) => void;
    motivoReprovar: ModalAction;

    setExcluirItem: (value: ModalAction) => void;
    excluirItem: ModalAction;
}

const UseTemplateContext = createContext<IuseWhiteList>({} as IuseWhiteList);

interface WhiteListData extends PaginationConfig {
    data: WhiteList[];
}

interface WhiteList {
    approved_user: null | User;
    approved_user_id: null | string;
    created_at: string;
    deleted_at: null | string;
    document: string;
    file: string;
    id: number;
    name: string;
    pending: number;
    reproved_user: null | User;
    reproved_user_id: null | string;
    status: string;
    updated_at: string;
    user: User;
    user_id: number;
    validated_at: null | string;
    reproved_reason: string;
}

export interface Tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
    queryParam: string;
}

const arrItens: Tabs[] = [
    {
        id: 1,
        title: "documentos para aprovar",
        className: "aprovar",
        active: true,
        viewer: true,
        permission: true,
        queryParam: "&status=AGUARDANDO",
    },
    {
        id: 2,
        title: "documentos aprovados",
        className: "aprovados",
        active: false,
        viewer: false,
        permission: false,
        queryParam: "&status=APROVADO",
    },
    {
        id: 3,
        title: "documentos reprovados",
        className: "reprovados",
        active: false,
        viewer: false,
        permission: false,
        queryParam: "&status=REPROVADO",
    },
];

export type ModalAction = {
    id: number;
    active: boolean;
    image?: string;
    text?: string;
};

export function WhiteListProvider(props: useWhiteListProviderProps) {
    const { children } = props;
    const [tabs, setTabs] = useState(arrItens);
    const [verSelfie, setVerSelfie] = useState({} as ModalAction);
    const [aprovarDoc, setAprovarDoc] = useState({} as ModalAction);
    const [reprovarDoc, setReprovarDoc] = useState({} as ModalAction);
    const [motivoReprovar, setMotivoReprovar] = useState({} as ModalAction);
    const [excluirItem, setExcluirItem] = useState({} as ModalAction);
    const [whiteLists, setWhiteLists] = useState<WhiteList[]>([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const [modaldialog, setModaldialog] = useState(false);
    const [serach, setSearch] = useState("");
    const [pagination, setPagination] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [whiteList, setWhiteList] = useState<WhiteList>({} as WhiteList);
    const history = useHistory<HistoryProps>();
    const historyId = history?.location?.state?.id;

    function handleTab(id: number) {
        const newTabs = tabs.map((item) => {
            if (item.id === id) {
                item.active = true;
                item.viewer = true;
            } else {
                item.active = false;
            }

            return item;
        });

        setTabs(newTabs);
    }

    async function store(formDate: FormData) {
        try {
            const response = await api.post("/whitelist-document", formDate);
            toast.success("Sucesso");

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Alguma coisa deu errado!");
            return error;
        }
    }

    async function update(formDate: FormData) {
        try {
            const response = await api.post(
                `whitelist-document/${historyId}`,
                formDate
            );
            toast.success("Sucesso");

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Alguma coisa deu errado!");
            return error;
        }
    }

    async function deleteList(id: string) {
        try {
            const response = await api.delete(`whitelist-document/${id}`);
            await getData();

            if (response.status === 200) {
                toast.success("Item excluido com sucesso");
            }
        } catch (error) {
            toast.error("Erro ao apagar item");
            console.log(error);
        }
    }

    async function aprovar(id: number) {
        try {
            await api.post(`/whitelist-document/${id}/aprovar`);
            await getData();
            toast.success("Usuario aprovado com sucesso");
            history.push("/sistema/white-list");
        } catch (error) {
            toast.error("Erro ao aprovar usuario");
        }
    }
    async function reprovar(value: string) {
        try {
            await api.post(`/whitelist-document/${reprovarDoc.id}/reprovar`, {
                reproved_reason: value,
            });
            await getData();
            toast.success("Usuario reprovado com sucesso");
            history.push("/sistema/white-list");
        } catch (error) {
            toast.error("Erro ao reprovado usuario");
        }
    }

    const getData = useCallback(
        async (page?: number) => {
            const hasPage = page ? `&page=${page}` : "";
            const params = tabs.find((item) => item.active)?.queryParam;
            const serachParam = serach ? `&search=${serach}` : "";
            try {
                setLoading(true);
                const response = await api.get<WhiteListData>(
                    `/whitelist-document?${hasPage}${
                        params ? params : ""
                    }${serachParam}`
                );
                const { data, ...resto } = response.data;
                setPagination(resto);
                setWhiteLists(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error("Erro ao listar white list");
                console.log(error);
            }
        },
        [tabs, serach]
    );

    useEffect(() => {
        if (history.location.pathname === "/sistema/white-list") {
            getData();
        }
    }, [getData, history]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.get<WhiteList>(
                    `whitelist-document/${historyId}`
                );
                setWhiteList(response.data);
            } catch (error) {
                toast.error("Erro ao listar white list");
                console.log(error);
            }
        }

        if (historyId) {
            fetchData();
        }
    }, [historyId]);

    return (
        <UseTemplateContext.Provider
            value={{
                setId,
                id,
                aprovar,
                reprovar,
                setSearch,
                modaldialog,
                setModaldialog,
                tabs,
                handleTab,
                getData,
                store,
                update,
                whiteLists,
                whiteList,
                deleteList,
                loading,
                pagination,
                setVerSelfie,
                verSelfie,
                aprovarDoc,
                setAprovarDoc,
                reprovarDoc,
                setReprovarDoc,
                motivoReprovar,
                setMotivoReprovar,
                excluirItem,
                setExcluirItem,
            }}
        >
            {children}
        </UseTemplateContext.Provider>
    );
}

export function useWhiteList() {
    const context = useContext(UseTemplateContext);
    return context;
}
