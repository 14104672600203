import { useHistory } from "react-router-dom";
import LogoImg from "../../assets/img/logo-menu.svg";
import { TimeIcon } from "../../assets/img/TimeIcon";
import { UseModal } from "../../Hook/Usemodal";
import { Container, LogoContainer, ModalContent } from "./styles";

export function TempoExpirado() {
    const { handleClose } = UseModal();
    const history = useHistory();
    function handleClick() {
        handleClose("tempoExpirado");
        history.push("/precisa-ajuda");
    }

    return (
        <Container>
            <LogoContainer>
                <img src={LogoImg} alt="logo" />
            </LogoContainer>

            <ModalContent>
                <TimeIcon />

                <h1>validação não concluída</h1>

                <p>
                    <strong>Estamos com instabilidades no sistema.</strong>
                    <br />
                    Tente novamente mais tarde ou entre em contato com o{" "}
                    <strong>
                        <button onClick={handleClick}>Fale Conosco.</button>
                    </strong>
                </p>
            </ModalContent>
        </Container>
    );
}
