import ImgError from "../../../assets/img/modal-error.svg";
import { UseModal } from "../../../Hook/Usemodal";
import { ThirdStrikeModalContainer } from "./styles";

export const ThirdStrikeModal = () => {
    const { handleClose, setState, state } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    function handleForgotPassword() {
        setState({
            ...state,
            esqueciSenha: true,
            avisoSenhaInvalida: false,
        });
    }

    return (
        <ThirdStrikeModalContainer>
            <img className="img-close" src={ImgError} alt="" />

            <h1>{"Seu acesso\nfoi bloqueado!"}</h1>
            <strong>
                Você excedeu o número máximo de tentativas de login ou
                identificamos alguma inconsistência. Por questões de segurança,
                sua conta foi bloqueada.
            </strong>

            <p>
                Clique no botão abaixo e redefina sua senha para recuperar o
                acesso
            </p>

            <button className="primary" onClick={handleForgotPassword}>
                Redefinir Senha
            </button>

            <button onClick={handleCloseModal}>voltar</button>
        </ThirdStrikeModalContainer>
    );
};
