
export const ModalErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
    <circle cx="38" cy="38" r="38" fill="#FFD5D9"/>
    <path d="M42.4808 33.5192L33.5192 42.4808" stroke="#EB001B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M42.4808 42.4808L33.5192 33.5192" stroke="#EB001B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38 52.25V52.25C30.1292 52.25 23.75 45.8708 23.75 38V38C23.75 30.1292 30.1292 23.75 38 23.75V23.75C45.8708 23.75 52.25 30.1292 52.25 38V38C52.25 45.8708 45.8708 52.25 38 52.25Z" stroke="#EB001B" stroke-width="2.375" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
