import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 20px;
    .container-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }
`;
