import imgCliente from "../../../assets/img/sistema/menu/cliente.svg";
import imgCampanha from "../../../assets/img/sistema/menu/campanha.svg";
import imgComunicacao from "../../../assets/img/sistema/menu/comunicacao.svg";
import imgUsuario from "../../../assets/img/sistema/menu/usuario.svg";
import imgCupom from "../../../assets/img/sistema/menu/cupom.svg";
// import imgCartaoVirtual from "../../../assets/img/sistema/menu/cartao-virtual.svg";
import imgBuscarCartao from "../../../assets/img/sistema/menu/buscar-cartao.svg";
import imgAdmin from "../../../assets/img/sistema/menu/admin.svg";
import imgAprovarTransferencia from "../../../assets/img/sistema/menu/aprovar-transferencia.svg";
import imgFaleConosco from "../../../assets/img/sistema/menu/fale-conosco.svg";
import imgFaq from "../../../assets/img/sistema/menu/faq.svg";
import imgTermos from "../../../assets/img/sistema/menu/termos.svg";
import imgWhite from "../../../assets/img/sistema/menu/white.svg";
import imgSerpro from "../../../assets/img/sistema/menu/serpro.svg";
import imgRelatorio from "../../../assets/img/menu/relatorio.svg";
import imgUpload from "../../../assets/img/menu/upload-files.svg";
import imgConfig from "../../../assets/img/menu/config.svg";

export interface Links {
    label: string;
    icon: string;
    href: string;
    permission?: string;
}

export const links: Links[] = [
    {
        label: "CLIENTE",
        href: "/sistema/cliente",
        icon: imgCliente,
        permission: "cliente.index",
    },
    {
        label: "CAMPANHA",
        href: "/sistema/campanha",
        icon: imgCampanha,
        permission: "campanha.index",
    },
    {
        label: "COMUNICAÇÃO",
        href: "/sistema/comunicacao",
        icon: imgComunicacao,
        permission: "acao.index",
    },
    {
        label: "RELATÓRIOS",
        href: "/sistema/relatorio",
        icon: imgRelatorio,
        permission: "reports.creditos_campanha.index",
    },
    {
        label: "USUÁRIOS",
        href: "/sistema/usuarios",
        icon: imgUsuario,
        permission: "usuario.search",
    },
    {
        label: "CUPOM",
        href: "/sistema/cupom",
        icon: imgCupom,
        permission: "cupom.index",
    },

    // {
    //     label: "CARTÃO VIRTUAL",
    //     href: "/sistema/cartao-virtual",
    //     icon: imgCartaoVirtual,
    //     permission: "carga.index",
    // },

    {
        label: "BUSCAR CARTÃO",
        href: "/sistema/buscar-cartao",
        icon: imgBuscarCartao,
        permission: "cartao.search",
    },

    {
        label: "ADMIN – PERMISSÕES",
        href: "/sistema/administracao",
        icon: imgAdmin,
        permission: "role.index",
    },

    {
        label: "APROVAR TRANSFERÊNCIA",
        href: "/sistema/aprovacao-de-transferencia",
        icon: imgAprovarTransferencia,
        permission: "termo_politica.index",
    },

    {
        label: "FALE CONOSCO",
        href: "/sistema/fale-conosco",
        icon: imgFaleConosco,
        permission: "helpdesk.index",
    },

    {
        label: "FAQ",
        href: "/sistema/faq",
        icon: imgFaq,
        permission: "faq.index",
    },

    {
        label: "TERMOS",
        href: "/sistema/termo-e-condicoes-de-uso",
        icon: imgTermos,
        permission: "termo_politica.index",
    },

    {
        label: "WHITE",
        href: "/sistema/white-list",
        icon: imgWhite,
        permission: "whitelist_document.index",
    },

    {
        label: "SERPRO",
        href: "/sistema/serpro",
        icon: imgSerpro,
        permission: "reports.serpro.requests_count",
    },
    {
        label: "UPLOAD",
        href: "/sistema/upload",
        icon: imgUpload,
        permission: "file.index",
    },
    {
        label: "BACKOFFICE",
        href: "/sistema/backoffice",
        icon: imgConfig,
        permission: "file.index",
    },
];
