import CryptoJS from "crypto-js";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import MobileDetec from "mobile-detect";
import moment from "moment";
import { MouseEvent, MutableRefObject } from "react";

import { BackofficeCard } from "../Hook/system/useBackoffice";
import { Campanha } from "../Hook/system/useCampanha";
import { LocalStoreProps } from "../Hook/types";
import { Cartoes } from "../Hook/user/UseCartaoes";
import { Campanhas } from "../Hook/user/UseUser";
import { options } from "../components/NovoSelect";
import { lightColors } from "../styles/theme";

export const tempoInicioPlataforma = String(9).padStart(2, "0");
export const tempoFimPlataforma = 17;

export function clearHeight(itens: HTMLDivElement[]) {
    itens.forEach(function (item) {
        if (item) {
            item.classList.remove("active");
            item.style.height = "75px";
        }
    });
}

export function formatCep(value: string) {
    if (!value) return "";
    value = value.replace(/^([0-9]{5})([0-9]{3})$/, "$1-$2");
    return value;
}

export function formatDateUser(date: string) {
    const dateIso = new Date(date);
    const dateFormated = dateIso.toLocaleDateString("pt-BR");

    return dateFormated;
}

export function limpaString(string: string) {
    string = string.toLowerCase().toString();
    var pattern = /[/\n\r\s\t]+/g;
    string = string.replace(new RegExp("[áàãâä]", "gi"), "a");
    string = string.replace(new RegExp("[éèêë]", "gi"), "e");
    string = string.replace(new RegExp("[íìîï]", "gi"), "i");
    string = string.replace(new RegExp("[óòõôö]", "gi"), "o");
    string = string.replace(new RegExp("[úùûü]", "gi"), "u");
    string = string.replace("/[ç]/ui", "c");
    string = string.replace("///g", "-");
    string = string.replace(/\./g, "-");
    string = string.replace(pattern, "-");
    return string;
}

export function getBaseUrl() {
    const hasSistema = window.location.href.indexOf("sistema") > 0;
    return hasSistema
        ? process.env.REACT_APP_BASE_HTTP_SISTEMA
        : process.env.REACT_APP_BASE_HTTP;
}

export function handleOpen(
    refItens: MutableRefObject<HTMLDivElement[]>,
    e: MouseEvent<Element>,
    key: number
) {
    let el = refItens.current[key];

    let altura = (el.querySelector(".conteudo") as HTMLElement).offsetHeight;

    const checkClass = (e.target as Element).classList.value;
    const checkTag = (e.target as Element).tagName;
    const elementosDisable = [
        "toggle",
        "active toggle",
        "false toggle",
        "undefined toggle",
    ];

    const tagsDisable = ["SPAN"];

    if (
        !elementosDisable.includes(checkClass) &&
        !tagsDisable.includes(checkTag)
    ) {
        if (el?.classList.value.includes("active")) {
            el.style.height = "75px";
            el.classList.remove("active");
        } else {
            clearHeight(refItens.current);
            el?.classList.add("active");

            if (el) {
                el.style.height = altura + "px";
            }
        }
    }
}

export const tokenType = {
    USER: "user",
    ADMIN: "admin",
} as const;

export type TokenType = typeof tokenType[keyof typeof tokenType];

export function getToken(type: TokenType): LocalStoreProps | undefined {
    const user = localStorage.getItem("user");
    if (user) {
        const userJson = JSON.parse(user);
        if (type === "admin") return userJson.token_adm;
        if (type === "user") return userJson.token_user;
    }
}

export function calcularTempoDecorridoEmSegundos(dataDoBanco: Date): number {
    const dataAtual = new Date();
    const diferencaEmMilissegundos =
        dataAtual.getTime() - dataDoBanco.getTime();
    const diferencaEmSegundos = Math.floor(diferencaEmMilissegundos / 1000);
    return diferencaEmSegundos;
}

export function getLocalStore(type?: TokenType): LocalStoreProps {
    const user = localStorage.getItem(type ? type : "user");
    if (user) {
        const userJson = JSON.parse(user);
        if (userJson.id) return userJson;
    }

    return {} as LocalStoreProps;
}
export function getCampanha(): Campanhas {
    const campanha = localStorage.getItem("campanha");

    if (campanha) {
        const campanhaJson = JSON.parse(campanha);
        if (campanhaJson[0].id)
            return campanhaJson.filter(
                (item: any) => item.id === getLocalStore().campanha_user.id
            )[0];
    }

    return {} as Campanhas;
}

export function setLocalStore(user: LocalStoreProps) {
    localStorage.setItem("user", JSON.stringify(user));
}

export function base64Hash(value: string) {
    return btoa(value);
}

export function formatCurrentMoney(value: number) {
    if (!value && !Number.isInteger(value)) return "";

    const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    })
        .format(value)
        .replace("R$", "");
    return valor;
}

export function filterCampanha(arr: Campanha[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter(
            (campanha) =>
                JSON.stringify(campanha, ["id", "nome", "chave", "status"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase()) ||
                JSON.stringify(campanha.cliente.areas, ["nome"])
                    .toLocaleLowerCase()
                    .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function filterCard(arr: BackofficeCard[], filtro: string) {
    if (arr.length && filtro !== "") {
        arr = arr.filter((campanha) =>
            JSON.stringify(campanha, [
                "id",
                "status",
                "stage",
                "printedName",
                "alias",
                "type",
                "issuingDate",
                "last4Digits",
                "contactlessEnabled",
                "bin",
            ])
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
        );

        return arr;
    }

    return arr;
}

export function formatDateTimeDb(date: string) {
    if (!date) return "";

    const dateTime = date.split(" ");
    const dateFormated = dateTime[0].split("/").reverse().join("-");
    const timeFormated = dateTime[1];
    return dateFormated + " " + timeFormated;
}

export function formatOnlyDateDb(date: string) {
    if (!date) return "";

    const dateTime = date.split(" ");
    return dateTime[0].split("/").reverse().join("-");
}

export function phoneNumber(phone: string) {
    if (phone.indexOf("(") < 0) {
        if (phone) {
            phone = phone.replace(/\D/g, "");
            phone = phone.replace("55", "");
            phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        }
        return phone;
    }
    return phone;
}

export function formataCPF(cpf: string) {
    //retira os caracteres indesejados...
    if (!cpf) return "";
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function foraDoHorario() {
    const agora = new Date();
    const hora = agora.getHours();

    return (
        hora < 9 ||
        hora >= tempoFimPlataforma ||
        itsWeekend() ||
        isHolidayToday()
    );
}

export function itsWeekend() {
    const agora = new Date();
    var dayOfWeek = agora.getDay();
    var isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    return isWeekend;
}

export async function sleep(seconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, seconds));
}

function isHolidayToday(): boolean {
    // Substitua esta lista com os feriados nacionais do Brasil
    const holidays: Record<string, boolean> = {
        "01-01": true, // Ano Novo
        "04-07": true, // Sexta-feira Santa (feriado)
        "04-21": true, // Dia de Tiradentes
        "05-01": true, // Dia do Trabalho
        "09-07": true, // Independência do Brasil
        "10-12": true, // Dia de Nossa Senhora Aparecida
        "11-02": true, // Dia de Finados
        "11-15": true, // Proclamação da República
        "12-25": true, // Natal
    };

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();

    const dateKey = `${currentMonth.toString().padStart(2, "0")}-${currentDay
        .toString()
        .padStart(2, "0")}`;

    return holidays[dateKey] === true;
}

export function formatDateIso(date: string | Date) {
    if (!date) return "";
    const dateTime = new Date(date);
    const dateFormated = dateTime.toLocaleDateString("pt-BR");
    const timeFormated = dateTime.toLocaleTimeString("pt-BR");
    const fullDate = dateFormated + " - " + timeFormated;
    return fullDate.replace(/\//g, ".");
}

export function formatDate(date: string) {
    const dateTime = new Date(date);
    return dateTime.toLocaleDateString("pt-BR");
}

export function formatDateIsoSystem(date: string | Date) {
    if (!date) return "";
    const dateTime = new Date(date);
    const dateFormated = dateTime.toLocaleDateString("pt-BR");
    const timeFormated = dateTime.toLocaleTimeString("pt-BR");
    const fullDate = dateFormated + " - " + timeFormated;
    return fullDate.replace(/\//g, "/");
}

export function formatNumber(number: number) {
    const formatter = new Intl.NumberFormat("pt-BR");
    return formatter.format(number);
}

export function formatDateSystem(date: string) {
    const dateTime = new Date(date);
    return dateTime.toLocaleDateString("pt-BR").replace(/\//g, "/");
}

export function convertDateDB(date: string) {
    if (!date) return "";
    const newdate = date.replace(
        /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})/g,
        "$3-$2-$1"
    );
    return newdate;
}

export function formataMoney(value: string) {
    if (!value) return "";
    const validate = /^0\.([1-9])$/;
    value = value.replace(/\D/g, "");

    if (parseInt(value) + "" === "0") return "R$ 0,00";

    if (value[0] === "0" && value[1] === "0") {
        value = parseFloat(value) / 100 + "";
    }

    if (value.length <= 2) {
        value = parseFloat(value) / 100 + "";
    }

    if (validate.test(value)) {
        value = value.replace(validate, "0.$10");
        value = value.replace(/(\d)(\d{2})$/, "$1,$2");
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");
        return "R$ " + value.replace(/\./g, ",");
    }

    value = parseFloat(value) + "";
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    if (value.length === 4) {
        value = value.replace(/\./g, ",");
    }

    if (isNaN(parseFloat(value))) {
        return "R$ 0,00";
    }

    return "R$ " + value;
}

export function maskDateRegex(value: string) {
    if (!value) return "";

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    return value;
}

export function getLastFourDigits(value: string) {
    if (!value) return "";

    value = value.toString().substring(value.length - 4, value.length);

    return value;
}

export function getAllMonthName(mes: string) {
    switch (mes) {
        case "01":
            return "Jan";
        case "02":
            return "Fev";
        case "03":
            return "Mar";
        case "04":
            return "Abr";
        case "05":
            return "Mai";
        case "06":
            return "Jun";
        case "07":
            return "Jul";
        case "08":
            return "Ago";
        case "09":
            return "Set";
        case "10":
            return "Out";
        case "11":
            return "Nov";
        case "12":
            return "Dez";
        default:
            return "";
    }
}

function getAllMonthNameIngles(mes: string) {
    switch (mes) {
        case "01":
            return "Jan";
        case "02":
            return "Feb";
        case "03":
            return "Mar";
        case "04":
            return "Apr";
        case "05":
            return "May";
        case "06":
            return "Jun";
        case "07":
            return "Jul";
        case "08":
            return "Aug";
        case "09":
            return "Sep";
        case "10":
            return "Oct";
        case "11":
            return "Nov";
        case "12":
            return "Dec";
        default:
            return "";
    }
}

export function getMonth(mes: string) {
    switch (mes) {
        case "jan":
            return "01";
        case "feb":
            return "02";
        case "mar":
            return "03";
        case "apr":
            return "04";
        case "may":
            return "05";
        case "jun":
            return "06";
        case "jul":
            return "07";
        case "aug":
            return "08";
        case "sep":
            return "09";
        case "oct":
            return "10";
        case "nov":
            return "11";
        case "dec":
            return "12";
        default:
            return "";
    }
}

// function countMonths(date: Date): number {
//     const currentDate = new Date(date);
//     const currentMonth = currentDate.getMonth();
//     const currentYear = currentDate.getFullYear();

//     const specifiedMonth = date.getMonth();
//     const specifiedYear = date.getFullYear();

//     let months =
//         (specifiedYear - currentYear) * 12 + specifiedMonth - currentMonth;

//     if (specifiedYear === currentYear && specifiedMonth < currentMonth) {
//         months -= 1;
//     }

//     return Math.abs(months);
// }

function countYearsFromMonths(months: number): number {
    return Math.floor(months / 12);
}

function getMonthFromMonths(months: number): number {
    if (((months % 12) + 12) % 12 === 0) {
        return 12;
    }

    return ((months % 12) + 12) % 12;
}

// export function getAllMonthToCurrentDay(dt: string) {
//     const dataAtual = new Date();
//     const format = formatDateIsoSystem(dt).replace(
//         /(\d{2})\/(\d{2})\/(\d{4}) - (\d{2}):(\d{2}):(\d{2})/,
//         "$3-$2-01"
//     );

//     const data = new Date(format);

//     const mesesAbreviados = [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//     ];

//     const meses = [];
//     const diffAnos = dataAtual.getFullYear() - data.getFullYear();
//     const diffMeses = diffAnos * 12 + dataAtual.getMonth() - data.getMonth();

//     for (let i = 0; i <= diffMeses; i++) {
//         const mesAtual = new Date(data);
//         mesAtual.setMonth(data.getMonth() + i);

//         const formatoMes = `${translateMonth(
//             mesesAbreviados[mesAtual.getMonth()]
//         )}. ${mesAtual.getFullYear()} . ${
//             mesesAbreviados[mesAtual.getMonth()]
//         }`;

//         const mes = { active: i === diffMeses, nome: formatoMes };
//         meses.push(mes);
//     }

//     return meses;
// }

export function translateMonth(mes: string) {
    switch (mes) {
        case "Jan":
            return "Jan";
        case "Feb":
            return "Fev";
        case "Mar":
            return "Mar";
        case "Apr":
            return "Abr";
        case "May":
            return "Mai";
        case "Jun":
            return "Jun";
        case "Jul":
            return "Jul";
        case "Aug":
            return "Ago";
        case "Sep":
            return "Set";
        case "Oct":
            return "Out";
        case "Nov":
            return "Nov";
        case "Dec":
            return "Dez";
        default:
            return "";
    }
}

export function getAllMonthToCurrentDay(dt: string) {
    // const date = new Date("2023-09-01T19:49:42.000000Z");
    // const date = new Date("2020-11-25T17:21:47.000000Z");
    const dataAtual = new Date();
    const format = formatDateIsoSystem(dt).replace(
        /(\d{2})\/(\d{2})\/(\d{4}) - (\d{2}):(\d{2}):(\d{2})/,
        "$3-$2-01"
    );

    const date = new Date(format);

    const diffAnos = dataAtual.getFullYear() - date.getFullYear();
    const diffMeses = diffAnos * 12 + dataAtual.getMonth() - date.getMonth();

    let currentYear = date.getFullYear();
    const month = [];

    for (let i = 0; i <= diffMeses; i++) {
        var mes = getMonthFromMonths(date.getMonth() + 1 + i);

        var ano = currentYear + countYearsFromMonths(date.getMonth() + i);

        month.push({
            nome: `${getAllMonthName(
                `${mes}`.padStart(2, "0")
            )}. ${ano} . ${ano}-${getAllMonthNameIngles(
                `${mes}`.padStart(2, "0")
            )}`,
            active: diffMeses === i,
        });
    }

    return month;
}

export function getFirsDayMonth(data?: Date) {
    const hasiPhone = new MobileDetec(window.navigator.userAgent).is("iPhone");
    const hasSafari =
        new MobileDetec(window.navigator.userAgent).userAgent() === "Safari";

    if (!data) {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        return firstDay;
    }

    if (hasiPhone) {
        const date = data ? new Date(data) : new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month + 1, 1);
        return firstDay;
    }

    if (hasSafari) {
        const date = data ? new Date(data) : new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month + 2, 1);
        return firstDay;
    }

    const date = data ? new Date(data) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    return firstDay;
}

export function getLastDayMonth(data?: Date) {
    const hasiPhone = new MobileDetec(window.navigator.userAgent).is("iPhone");
    const hasSafari =
        new MobileDetec(window.navigator.userAgent).userAgent() === "Safari";

    if (!data) {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const lastDay = new Date(year, month + 1, 0);
        return lastDay;
    }

    if (hasiPhone) {
        const date = data ? new Date(data) : new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const lastDay = new Date(year, month + 2, 0);
        return lastDay;
    }

    if (hasSafari) {
        const date = data ? new Date(data) : new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const lastDay = new Date(year, month + 3, 0);
        return lastDay;
    }

    const date = data ? new Date(data) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDay = new Date(year, month + 1, 0);
    return lastDay;
}

export function generateSalt() {
    return CryptoJS.lib.WordArray.random(16).toString();
}

export function getRandomArbitrary() {
    return Math.floor(Math.random() * 100);
}

export function base_64(text: string) {
    const encodedWord = CryptoJS.enc.Utf8.parse(text);
    return CryptoJS.enc.Base64.stringify(encodedWord);
}

export function generateKey(salt: string) {
    var date = moment().format("YYYY-MM-DD HH:mm:ss");
    var final = `${salt}.${date}`;
    return base_64(final);
}

export function crypted(...args: string[]) {
    const dados: any = {};
    try {
        const salt = generateSalt();
        const hash = generateKey(salt);

        let keyHex = encHex.parse(salt);
        let iv = encHex.parse("00000000000000000000000000000000");

        args.forEach((agr) => {
            const itens = agr.split("#");
            const key = itens[0];
            const value = itens[1];

            dados[key] =
                aes
                    .encrypt(value, keyHex, { iv, padding: padZeroPadding })
                    .toString() || "";
        });

        return {
            hash,
            ...dados,
        };
    } catch (error) {
        args.forEach((agr) => {
            const itens = agr.split(".");
            const key = itens[0];
            const value = itens[1];

            dados[key] = value;
        });
        return {
            hash: "",
            ...dados,
        };
    }
}

export function decrypted(dataEncrypt: string, salt: string) {
    if (!dataEncrypt || !salt) return "";

    try {
        var dataKey = CryptoJS.enc.Utf8.parse(salt); //salt
        var dataVector = CryptoJS.enc.Utf8.parse("0000000000000000");
        var decrypted = CryptoJS.AES.decrypt(dataEncrypt, dataKey, {
            iv: dataVector,
        });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    } catch (error) {
        return "";
    }
}

export function msg422(msg: any) {
    const keys = Object.keys(msg);
    const filteredKeys = keys.filter((key) => key !== "success");

    return filteredKeys
        .map((key: string) => {
            return msg[key];
        })
        .join(", ");
}

// export function cardNumberFormat(cardNumber: string) {
//     if (!cardNumber) return "";

//     return cardNumber.replace(
//         /^([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})/g,
//         "$1 **** **** $4"
//     );
// }

export function cardDateFormat(cardNumber: string) {
    if (!cardNumber) return "";

    return cardNumber.replace(/^([0-9]{2})([0-9]{2})/g, "**/**");
}

export function cardCVVFormat(cardNumber: string) {
    if (!cardNumber) return "";

    return cardNumber.replace(/^([0-9]{3})/g, "***");
}

export function podesolicitarCartao(
    campanhas: Campanhas[],
    cartoes: Cartoes[]
) {
    let options: options[] = [
        // {
        //     value: "cartao_fisico_avulso",
        //     label: "CARTÃO FÍSICO AVULSO",
        // },
    ];
    if (campanhas[0]) {
        campanhas[0].produtos.forEach((prod) => {
            if (prod.produto.tipo === "cartao_fisico") {
                const count = cartoes.reduce((acc, item) => {
                    if (item.tipo === "cartao_fisico") {
                        return acc + 1;
                    }
                    return acc;
                }, 0);

                if (count < prod.lim_quantidade) {
                    options.push({
                        label: prod.produto.descricao,
                        value: "cartao_fisico",
                    });
                }
            }

            if (prod.produto.tipo === "cartao_virtual") {
                const count = cartoes?.reduce((acc, item) => {
                    if (item.tipo === "cartao_virtual") {
                        return acc + 1;
                    }
                    return acc;
                }, 0);

                if (count < prod.lim_quantidade) {
                    options.push({
                        label: prod.produto.descricao,
                        value: "cartao_virtual",
                    });
                }
            }
            // if (prod.produto.tipo === "cartao_avulso") {
            //     const count = cartoes.reduce((acc, item) => {
            //         if (item.tipo === "cartao_avulso") {
            //             return acc + 1;
            //         }
            //         return acc;
            //     }, 0);

            //     if (count < prod.lim_quantidade) {
            //         options.push({
            //             label: prod.produto.descricao,
            //             value: "cartao_avulso",
            //         });
            //     }
            // }
        });
    }
    return options;
}

export function arrayRandom() {
    var teste1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    var arrTemp: Array<Number[]> = [];

    while (teste1.length) {
        arrTemp.push([
            ...teste1.splice(
                parseInt((Math.random() * teste1.length).toString()),
                1
            ),
            ...teste1.splice(
                parseInt((Math.random() * teste1.length).toString()),
                1
            ),
        ]);
    }

    return arrTemp;
}

export function isDateInFuture(date: Date): boolean {
    const now = new Date();
    return date > now;
}

export function objCartaoes(
    cartoes: Cartoes[],
    active: boolean = false,
    visualizarDados = false
) {
    if (cartoes?.length === 0) return [];

    return cartoes?.map((cartao) => {
        return {
            id: cartao.id,
            price: "0.00",
            number: cartao.acg_account_card_last4digits,
            type: cartao.tipo !== "cartao_virtual" ? "FÍSICO" : "VIRTUAL",
            date: "**/**",
            status:
                cartao.status === "BLOQUEADO" && cartao.first_active === 0
                    ? "INATIVO"
                    : cartao.status,
            color:
                cartao.tipo !== "cartao_virtual"
                    ? lightColors.tertiarybright
                    : lightColors.tertiarydark,
            visualizarDados: visualizarDados
                ? visualizarDados
                : cartao.tipo === "cartao_virtual"
                ? true
                : false,
            active: active,
            order: cartao?.list_order,
        };
    });
}

export function formatNameCard(value: string) {
    if (!value) return "";
    var name = value.split(" ");
    var primeiroNome = name.shift();
    var ultimoNome = name.pop();
    var meio = name.reduce((acc, item) => {
        if (item.length > 2) {
            return acc + item[0] + " ";
        }
        return acc;
    }, "");

    return `${primeiroNome} ${meio}${ultimoNome}`.trim();
}

export function rangeDate(data_start: string, date_end: string) {
    const start = new Date(data_start).getTime();
    const end = new Date(date_end).getTime();
    const now = new Date().getTime();

    if (now >= start && now <= end) {
        return true;
    } else {
        return false;
    }
}

export const maskCPF_CNPJ = (v: string) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
        v = v.replace(/^(\d{2})(\d)/, "$1.$2");
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
};

export const maskCPF = (v: string) => {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
};

// export function rotasPermission() {
//     return getLocalStore().permissions.filter((permission) => {
//         // eslint-disable-next-line @typescript-eslint/no-unused-vars
//         const [rota, index] = permission.split(".");

//         if (index === "index") return permission;

//         return false;
//     });
// }

export function tipoCartao(value: string) {
    if (value === "cartao_avulso") return "Cartão Noname";
    if (value === "cartao_fisico") return "Cartão físico";
    if (value === "cartao_virtual") return "Cartão virtual";

    return "";
}

export function addDays(dt: string): string {
    // Convertendo a data para o formato de data do JavaScript
    const date = new Date(dt);

    // Adicionando 10 dias à data
    date.setDate(date.getDate() + 15);

    // Convertendo a data de volta para uma string no formato desejado
    return date.toISOString().slice(0, 10);
}

export function getOrderLocalStorage(): Array<any> | null {
    if (localStorage.getItem("order")) {
        return JSON.parse(localStorage.getItem("order") ?? "");
    }

    return null;
}

export function carregaItensNaOrdem(array: Array<any>) {
    return removerDuplicatasPorChave(array, "id");
}

export function removerDuplicatasPorChave(array: Array<any>, chave: string) {
    const mapa = new Map();
    const resultado = [];

    for (const item of array) {
        if (!mapa.has(item[chave])) {
            mapa.set(item[chave], true);
            resultado.push(item);
        }
    }

    return resultado;
}

export function validateEmail(email: string) {
    const validate = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    return validate.test(email);
}

export const cleanString = (s: string) => {
    return s.replace(/[^\d]/g, "");
  };