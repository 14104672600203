import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";


interface IBtnContainerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $theme: "primary" | "secondary"
}

export const BtnContainer = styled.button<IBtnContainerProps>`
  display: flex;
  width: 100%;
  max-width: 327px;

  color: #FFF;
  background-color: #57B847;
  justify-content: center;
  padding: 20px 0;
  border-radius: 200px;

  font-family: Oxanium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  
  transition: 0.4s all;

  ${({ $theme}) => $theme === "secondary" 
    ? css`
      background-color: unset;
      color: var(--Cores-Yetz-Preto, #000);
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
  ` : null}

  &:disabled {
    background: #E7E7E7;
    color: #5E5F5F;
    cursor: not-allowed
  }

  &:active {
    transform: scale(1.1);
  }
`