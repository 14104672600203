import { Container } from "./styles";
import imgLogo from "../../assets/img/logo-desktop.svg";
import imgCheckCadastro from "../../assets/img/check-cadastro.svg";
import { Button } from "../Mobile/Button";

export function CadastroEfetuado() {
    function handleClick() {
        window.location.href = "/dashboard";
    }

    return (
        <Container>
            <div className="header">
                <img src={imgLogo} alt="" />
            </div>

            <div className="container-check">
                <img src={imgCheckCadastro} alt="" />
            </div>

            <div className="container-text">
                <h3>
                    Cadastro Efetuado <br /> com sucesso
                </h3>
            </div>

            <div className="container-btn">
                <Button onClick={handleClick}>EFETUAR LOGIN</Button>
            </div>
        </Container>
    );
}
