import { useEffect, useState } from "react";
import { Delivery } from ".";
import imgEntrega from "../../assets/img/entrega.svg";
import { Loader } from "../Loader";
import { Container } from "./styles";

interface CardProps {
    loading: boolean;
    dados: Delivery[];
}

export function Card(props: CardProps) {
    const { dados = [], loading } = props;
    const [date, setDate] = useState("");
    const [link, setLink] = useState("");
    const [lastItem, setLastItem] = useState<number | null>(null);
    useEffect(() => {
        if (loading) {
            setLink("");
        }
    }, [loading]);

    useEffect(() => {
        if (dados.length > 0) {
            const dado = dados.find((data) => data.delivery_date);

            const haslink = dados.find((item) => item.linkrastreio);
            const findLastIndexActive = dados
                .map((item) => item.active)
                .lastIndexOf(true);
            setLastItem(findLastIndexActive);
            if (haslink?.linkrastreio) {
                setLink(haslink.linkrastreio);
            }

            setDate(dado?.delivery_date || "");
        }
    }, [dados]);

    return (
        <Container>
            <div className="container-title">
                <img src={imgEntrega} alt="" />
                <p>status da entrega</p>
                <span>{date ? `Previsão de entrega: ${date}` : ""}</span>
            </div>

            {loading ? (
                <div className="loading">
                    <Loader height={128} width={128} />
                </div>
            ) : dados.length ? (
                <ul>
                    {dados?.map((item, key) => {
                        if (!item.descricao) return <></>;

                        const last = lastItem === key ? "last" : "";

                        return (
                            <li
                                key={key}
                                className={`${
                                    item.active ? `active ${last}` : ""
                                }`}
                            >
                                <h4>{item.descricao}</h4>
                                <p>{item.date}</p>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <span>CARTÃO SOLICITADO. AGUARDANDO RETORNO DA POSTAGEM.</span>
            )}

            <div className="container-title bottom">
                {link ? (
                    <div className="link">
                        <p>
                            <a
                                href={link}
                                target="_blank"
                                className="container-text"
                                rel="noreferrer"
                            >
                                Clique aqui
                            </a>
                            para acompanhar o <br /> status no site da
                            transportadora.
                        </p>
                    </div>
                ) : null}
            </div>
        </Container>
    );
}
