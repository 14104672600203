import { Container } from "./styles";
import { Loader } from "../../../Loader";
import { useEffect, useState } from "react";
import { formatDateIsoSystem } from "../../../../util/helper";
import { Delivery } from "../../../Entrega";

import imgEntrega from "../../../../assets/img/entrega.svg";
// import imgSetaEsq from "../../../../assets/img/x.svg";

interface CardProps {
    loading: boolean;
    dados: Delivery[];
}

export function Card(props: CardProps) {
    const { dados = [], loading } = props;
    const [date, setDate] = useState("");
    // const [link, setLink] = useState("");

    useEffect(() => {
        if (loading) {
            // setLink("");
        }
    }, [loading]);

    useEffect(() => {
        if (dados.length > 0) {
            const dias = dados[0]?.date ? dados[0]?.date : "";

            const haslink = dados.find((item) => item.linkrastreio);
            if (haslink?.linkrastreio) {
                // setLink(haslink.linkrastreio);
            }

            setDate(dias);
        }
    }, [dados]);

    return (
        <Container>
            {/* <StyleHeader>
                <div className="container-btn">
                    <button onClick={() => {}}>
                        <img src={imgSetaEsq} alt="Voltar" />
                    </button>
                </div>

                <div className="container-passos">
                    <div></div>
                </div>
            </StyleHeader> */}

            <div className="container-title">
                <img src={imgEntrega} alt="" />
                <p>status da entrega</p>
                <span>{date ? `Previsão de entrega: ${date}` : ""}</span>
            </div>

            {loading ? (
                <div className="loading">
                    <Loader height={128} width={128} />
                </div>
            ) : dados.length ? (
                <ul>
                    {dados?.map((item, key) => {
                        return (
                            <li key={key}>
                                <h4
                                    className={`${item.active ? "active" : ""}`}
                                >
                                    {item.status}
                                </h4>
                                <p>{formatDateIsoSystem(item.momento_log)}</p>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <span>CARTÃO SOLICITADO. AGUARDANDO RETORNO DA POSTAGEM.</span>
            )}

            {/* <div className="container-title bottom">
                {link ? (
                    <div className="link">
                        <p>
                            <a
                                href={link}
                                target="_blank"
                                className="container-text"
                                rel="noreferrer"
                            >
                                Clique aqui
                            </a>
                            para acompanhar o <br /> status no site da
                            transportadora.
                        </p>
                    </div>
                ) : null}
            </div> */}
        </Container>
    );
}
