import { useState } from "react";

import { phoneNumber } from "../../../util/helper";

import { Reenviar } from "./reenviar";

import { UseLogin } from "../../../Hook/user/UseLogin";
import { CodeInput } from "../../CodeInput";
import {
    Container,
    CustomBtn,
    Description,
    MobileNumberViewer,
    Title,
} from "./styles";

interface ValidarProps {
    handleClick: () => void;
    pt?: boolean;
    phone_number: string;
}

export function Validadar(props: ValidarProps) {
    const { handleClick, phone_number } = props;
    const { confirmAuthCode } = UseLogin();

    const [errorCode, setErrorCode] = useState(false);
    const [code, setCode] = useState("");

    return (
        <Container>
            <Title>código de verificação</Title>
            <Description>
                <b>PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</b> enviado para o seu
                WhatsApp.
            </Description>

            {phone_number && (
                <MobileNumberViewer
                    type="text"
                    value={phoneNumber(phone_number)}
                    disabled
                />
            )}

            <CodeInput onChange={setCode} showError={errorCode} />

            <Reenviar />

            <CustomBtn
                disabled={code?.length < 5}
                onClick={async () => {
                    const responseCode = await confirmAuthCode(code);

                    if (responseCode?.data?.success) {
                        await handleClick();
                        return;
                    } else {
                        setErrorCode(true);
                    }
                }}
            >
                Acessar
            </CustomBtn>
        </Container>
    );
}
