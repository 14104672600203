import * as React from "react";

import { UseModal } from "../../Hook/Usemodal";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { ModalStyles, Container } from "./styles";
import imgError from "../../assets/img/modal-error.svg";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
}

export default function ModalError(props: ModalProps) {
    const { open, handleClose } = props;
    const { state } = UseModal();

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="modal-error"
        >
            <Container>
                <img src={imgError} alt="" />
                <h2>{state?.errorGeral.title}</h2>

                {state?.errorGeral.message && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: state?.errorGeral.message,
                        }}
                    ></p>
                )}
            </Container>
        </ModalStyles>
    );
}
