import { Container } from "./styles";
import imgCheck from "../../../../../assets/img/check-auth.svg";
import { Button } from "../../../Button";
import {
    ModalAction,
    useWhiteList,
} from "../../../../../Hook/system/useWhiteList";
import { useState } from "react";

export function AprovarDoc() {
    const { setAprovarDoc, aprovarDoc, aprovar } = useWhiteList();
    const [loading, setLoading] = useState(false);

    async function handleAprovar() {
        try {
            setLoading(true);
            await aprovar(aprovarDoc.id);
            setLoading(false);
            setAprovarDoc({} as ModalAction);
        } catch (error) {
            console.log(error);
            setLoading(false);
            throw new Error("Erro ao aprovar");
        }
    }

    return (
        <Container>
            <div className="container-check">
                <div className="container-img">
                    <img src={imgCheck} alt="" />
                </div>
            </div>

            <div className="container-text">
                <h3>
                    Tem certeza que <br /> deseja aprovar?
                </h3>
            </div>

            <div className="container-btns">
                <Button
                    className="confirmar"
                    onClick={handleAprovar}
                    isLoading={loading}
                >
                    sim
                </Button>

                <button onClick={() => setAprovarDoc({} as ModalAction)}>
                    CANCELAR
                </button>
            </div>
        </Container>
    );
}
