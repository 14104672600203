import { useCallback, useEffect, useState } from "react";

import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { crypted, getLocalStore, phoneNumber } from "../../../util/helper";
import { CodeInput } from "../../CodeInput";

import { Reenviar } from "./reenviar";

import {
    Container,
    CustomBtn,
    Description,
    MobileNumberViewer,
    Title,
} from "./styles";

interface ValidarProps {
    handleClick: () => Promise<void>;
    pt?: boolean;
    phone_number?: string;
    autoSend?: boolean;
}

//usuário logado
export function Validadar(props: ValidarProps) {
    const { handleClick, phone_number, autoSend = true } = props;

    const token = getLocalStore().token_user;
    const { confirmCode, confirmCodeSemToken } = useUser();

    const { validateUser, validateUserSemToken, user } = useUser();
    const { dados } = usePassos();
    const [errorCode, setErrorCode] = useState(false);
    const [code, setCode] = useState("");

    const mobile_number = phone_number || user?.phone_number;

    const sendSms = useCallback(async () => {
        if (phone_number && dados?.document) {
            await validateUserSemToken(
                "whatsapp",
                crypted(`document#${document}`)
            );
        } else {
            await validateUser("whatsapp");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (autoSend) {
            sendSms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Title>código de verificação</Title>
            <Description>
                <b>PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</b> enviado para o seu
                WhatsApp.
            </Description>

            {phone_number && (
                <MobileNumberViewer
                    type="text"
                    value={phoneNumber(phone_number)}
                    disabled
                />
            )}

            <CodeInput onChange={setCode} showError={errorCode} />

            <Reenviar mobile_number={mobile_number} />

            <CustomBtn
                disabled={code?.length < 5}
                onClick={async () => {
                    const responseCode =
                        phone_number && !token
                            ? await confirmCodeSemToken({
                                  ...crypted(
                                      `code#${code}`,
                                      `document#${dados.document}`,
                                      `email#${dados.email}`
                                  ),
                                  birth_date: dados.birth_date,
                              })
                            : await confirmCode(code);

                    if (responseCode?.data?.success) {
                        await handleClick();
                    } else {
                        setErrorCode(true);
                    }
                }}
            >
                Acessar
            </CustomBtn>
        </Container>
    );
}
