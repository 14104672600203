import styled from "styled-components";

export const ErrorMessageContainer = styled.span`
    display: flex;

    color: #d50632;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
`;
