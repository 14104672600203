/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";

import { useLogin } from "../../../../../../Hook/system/useLogin";
import { CodeInput } from "../../../../../CodeInput";
import { ResendCode } from "../../../../../ResendCode";
import { Timer } from "../../../../../Timer";

import {
    CustomBtn,
    Description,
    DisabledInput,
    PasswordContent,
    PasswordStepContainer,
    Title,
} from "./styles";

interface IConfirmTokenProps {
    onNextPage: () => void;
}

export const ConfirmToken = ({ onNextPage }: IConfirmTokenProps) => {
    const {
        confirmAuthCode,
        genereteAuthCode,
        user,
        setModalConfirm,
        setAuthenticator,
    } = useLogin();

    const formatedPhoneNumber = user.user.phone_number || "";

    const [code, setCode] = useState("");
    const [timerHasTimeOut, setTimerHasTimeOut] = useState(false);

    const handleTimerHasTimeOut = useCallback(async () => {
        setTimerHasTimeOut(true);
    }, []);

    const handleConfirmToken = useCallback(async () => {
        const isFirstUpdate = user.user.first_update_password === 0;
        const { success } = await confirmAuthCode(code);

        if (success) {
            if (isFirstUpdate && onNextPage) {
                return onNextPage();
            }

            setModalConfirm(false);
            setAuthenticator(true);
        }
    }, [
        code,
        confirmAuthCode,
        onNextPage,
        setModalConfirm,
        setAuthenticator,
        user,
    ]);

    const handleResendToken = useCallback(() => {
        setTimerHasTimeOut(false);
        genereteAuthCode();
    }, []);

    useEffect(() => {
        genereteAuthCode();
    }, []);

    return (
        <PasswordStepContainer data-testid="password-modal">
            <PasswordContent>
                <Title>código de verificação</Title>
                <Description>
                    <b>PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</b>
                    {"\nenviado para o seu WhatsApp."}
                </Description>

                <DisabledInput disabled placeholder={formatedPhoneNumber} />
                <CodeInput onChange={(e) => setCode(e)} />

                {!timerHasTimeOut && (
                    <Timer onComplete={handleTimerHasTimeOut} />
                )}

                <CustomBtn
                    onClick={handleConfirmToken}
                    disabled={code.length <= 4}
                >
                    Acessar
                </CustomBtn>

                {timerHasTimeOut && (
                    <ResendCode
                        onResend={handleResendToken}
                        sentTo={"WhatsApp"}
                        useWhatsApp
                        user={{
                            mobile_number: formatedPhoneNumber,
                        }}
                    />
                )}
            </PasswordContent>
        </PasswordStepContainer>
    );
};
