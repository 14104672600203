import { Container } from "./styles";
import imgX from "../../../../../assets/img/x-white-list.svg";
import { Button } from "../../../Button";
import {
    ModalAction,
    useWhiteList,
} from "../../../../../Hook/system/useWhiteList";
import { useState } from "react";

export function Reprovar() {
    const { setReprovarDoc, reprovar } = useWhiteList();
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleReprovar() {
        try {
            setLoading(true);
            await reprovar(value);
            setLoading(false);
            setReprovarDoc({} as ModalAction);
        } catch (error) {
            console.log(error);
            setLoading(false);
            throw new Error("Erro ao reprovar");
        }
    }

    return (
        <Container>
            <div className="container-check">
                <div className="container-img">
                    <img src={imgX} alt="" />
                </div>
            </div>

            <div className="container-text">
                <h3>
                    Tem certeza que <br />
                    deseja reprovar?
                </h3>

                <p>Digite o motivo da reprovação</p>

                <div className="container-form">
                    <textarea
                        placeholder="Digite motivo da reprovação"
                        name=""
                        id=""
                        cols={30}
                        rows={10}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    ></textarea>
                </div>
            </div>

            <div className="container-btns">
                <Button
                    className="confirmar"
                    disabled={value.length < 5}
                    onClick={handleReprovar}
                    isLoading={loading}
                >
                    sim
                </Button>

                <button onClick={() => setReprovarDoc({} as ModalAction)}>
                    CANCELAR
                </button>
            </div>
        </Container>
    );
}
