import { BrowserRouter } from "react-router-dom";
import { SwitchRoutes } from "./components/SwitchRoutes";

import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

import { GlobalStyle } from "./styles/global";
import Interceptor from "./services/interceptor";
import "./styles/fonts.css";
import "../node_modules/swiper/swiper.min.css";
import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";
import "react-toastify/dist/ReactToastify.css";

export function App() {
    const theme = createTheme(
        {
            palette: {
                primary: { main: "#5DB356" },
            },
        },
        ptBR
    );

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Interceptor />
            <BrowserRouter>
                <SwitchRoutes />
                <ToastContainer />
            </BrowserRouter>
        </ThemeProvider>
    );
}
