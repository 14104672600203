import { SucessoStyles } from "./styles";
import { Button } from "../Button";
import imgCartao from "../../../assets/img/recuperar-senha.svg";
import { UseModal } from "../../../Hook/Usemodal";

export function Sucesso() {
    const { handleClose } = UseModal();

    return (
        <SucessoStyles>
            <img src={imgCartao} alt="" />
            <h1>SENHA ALTERADA COM SUCESSO!</h1>
            <p>Use sua nova senha para acessar seu dashboard na YETZPAY!</p>
            <Button onClick={() => handleClose("esqueciSenha")}>
                OK, IR PARA O LOGIN
            </Button>
        </SucessoStyles>
    );
}
