import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-error": {
        zIndex: 9999,
    },
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        overflowY: "unset",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "24px",
    },
    ".container": {
        width: "100%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: "auto",
        },
    },
}));

export const Container = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 74px;
    padding-bottom: 63px;
    position: relative;
    img {
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 20px */

        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: ${({ theme }) => theme.colors.failure};
        padding: 0 20px;
    }

    p {
        min-width: 274px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-top: 19px;
        padding: 0px 20px;
    }
`;
