import styled from "styled-components";

export const Container = styled.div`
    img {
        object-fit: contain;
        width: auto;
        height: auto;
        max-width: 70vw;
        max-height: 80vh;
    }
`;
