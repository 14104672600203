import styled from "styled-components";

export const Container = styled.div`
    background-color: #f4f4f4;
    border-radius: 8px;
    padding-top: 58px;
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 44px;
    .container-img {
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, 4%);
    }

    .container-descricao {
        text-align: center;
        margin-bottom: 17px;
        p {
            font-size: 16px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.secondary};
        }
        span {
            font-size: 14px;
            color: ${({ theme }) => theme.colors.primaryDark};
        }
    }

    .container-inputs {
        display: flex;
        justify-content: center;

        input {
            padding-left: unset !important;
            width: 67px !important;
            height: 67px !important;
            text-align: center !important;
            border-radius: 50% !important;
        }
    }
`;
