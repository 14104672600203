import styled from "styled-components";
import { Container as DefaultHeader } from "../Header/styles";

export const Container = styled.div`
    .table {
        .tr {
            &.body {
                cursor: pointer;
            }
        }
    }
`;

export const StylesHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e1e2e3;
    .container-toggle {
        display: flex;
        align-items: center;
        margin-right: 32px;
    }
    ${DefaultHeader} {
        border: none;
        margin-right: auto;
    }
    button {
        width: auto;
        margin: 0px;
        margin-left: 11px;
    }
    .container-input-system {
        margin-bottom: 0px;
        width: 532px;
        position: relative;
        margin-right: 44px;
        input {
            padding-left: 45px;
            border: none;
        }
    }
`;
