import styled from "styled-components";
import { Container as HeaderPassos } from "../../../Mobile/HeaderPassos/styles";

export const Container = styled.div`
    padding-bottom: 41px;

    .loading {
        margin-top: 32px;
    }

    ${HeaderPassos} {
        .container-passos {
            .active {
                border-bottom: 1px solid #e1e2e3;
            }
        }
    }
    .container-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 23px;
        &.bottom {
            margin-top: 173px;
        }

        .link {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 16px;
            p {
                a {
                    color: ${(props) => props.theme.colors.secondary};
                    font-weight: bold;
                    display: inline-block;
                    margin-right: 4px;
                }
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
            margin: 0px;
            margin-top: 1px;
        }

        a {
            text-align: center;
        }

        img {
            width: 56px;
            display: block;
            margin-bottom: 18px;
        }
    }

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primaryDark};
        text-align: center;
        display: block;
        margin-top: 32px;
    }

    ul {
        padding-left: 71px;
        margin-top: 44px;
        position: relative;
        &:before {
            content: "";
            width: 2px;
            height: calc(100% - 50px);
            display: block;
            position: absolute;
            top: 20px;
            left: 40.89px;
            background-color: ${(props) => props.theme.colors.secondary};
        }

        li {
            width: 276px;
            margin-bottom: 28px;
            position: relative;

            &:before {
                content: "";
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 7px;
                left: -37px;
                background-color: ${(props) => props.theme.colors.secondary};
                z-index: 20;
            }

            &:last-child {
                margin-bottom: 0px;
                h4 {
                    color: ${(props) => props.theme.colors.secondary};
                }
                &:after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    top: 1.7px;
                    left: -42px;
                    background-color: white;
                    border-radius: 50%;
                    border: 1px solid ${(props) => props.theme.colors.secondary};
                    z-index: 10;
                }
            }
            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.primaryBright};
                &.active {
                    color: ${(props) => props.theme.colors.secondary};
                }
            }

            h5 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.secondary};
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.primaryBright};
            }
        }
    }
`;

export const StyleHeader = styled.div`
    height: 61px;
    padding: 0 24px;
    .container-passos {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        div {
            display: block;
            height: 1px;
            width: 100%;
            margin-right: 4px;
            border-bottom: 1px solid #e1e2e3;
            &.active {
                border-bottom: 1px solid
                    ${(props) => props.theme.colors.secondary};
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .container-btn {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
            font-weight: 500;
            font-family: "Oxanium";
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary} !important;
            margin-left: auto;
        }
    }
`;
