import { Container } from "./styles";
import imgLixeira from "../../../../../assets/img/lixeira-white-list.svg";
import { Button } from "../../../Button";
import {
    ModalAction,
    useWhiteList,
} from "../../../../../Hook/system/useWhiteList";
import { useState } from "react";

export function ExcluirDoc() {
    const { setExcluirItem, excluirItem, deleteList } = useWhiteList();
    const [loading, setLoading] = useState(false);

    async function handleDelete() {
        try {
            setLoading(true);
            await deleteList(excluirItem.id + "");
            setExcluirItem({} as ModalAction);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            throw new Error("Erro ao excluir");
        }
    }

    return (
        <Container>
            <div className="container-check">
                <div className="container-img">
                    <img src={imgLixeira} alt="" />
                </div>
            </div>

            <div className="container-text">
                <h3>
                    Tem certeza que <br />
                    deseja excluir?
                </h3>
            </div>

            <div className="container-btns">
                <Button
                    className="confirmar"
                    onClick={handleDelete}
                    isLoading={loading}
                >
                    sim
                </Button>

                <button onClick={() => setExcluirItem({} as ModalAction)}>
                    CANCELAR
                </button>
            </div>
        </Container>
    );
}
