import { useState } from "react";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import { Text } from "alisson-application";
import { ConfirToggleSmsStyles } from "./styles";
import imgSuccess from "../../../assets/img/success-user-side.svg";
export function ConfirmaToggleSms() {
    const { handleClose } = UseModal();
    const [loading, setLoading] = useState(false);
    const { smsCartaoToggle, cartao } = UseCartoes();

    async function handleToggle() {
        setLoading(true);
        await smsCartaoToggle();
        setLoading(false);
    }
    return (
        <ConfirToggleSmsStyles>
            <img src={imgSuccess} alt="" />
            {cartao?.acg_sms_service ? (
                <p>
                    Está certo de que deseja desativar as notificações via SMS.
                    Você não será mais informado das transações do seu cartão.
                </p>
            ) : (
                <p>
                    Ativar as notificações de transações, via SMS, tem um custo
                    de R$2,00 por mês, debitados diretamente do saldo do seu
                    cartão. Você concorda com essa cobrança?
                </p>
            )}

            <Button
                onClick={handleToggle}
                type="submit"
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
                mb="30px"
            >
                {cartao?.acg_sms_service
                    ? "SIM, DESATIVAR"
                    : "CONFIRMAR COBRANÇA"}
            </Button>

            <button onClick={() => handleClose("confirmaToggleSms")}>
                <Text color="primaryDark" fontSize="xs">
                    NÃO, CANCELAR
                </Text>
            </button>
        </ConfirToggleSmsStyles>
    );
}
