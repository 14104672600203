import { useState } from "react";
import { Text } from "alisson-application";

import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";

import { Button } from "../Button";

import { Container } from "./styles";
import imgError from "../../../assets/img/modal-error.svg";

export function BloquearCartao() {
    const { bloquear } = UseCartoes();
    const { setState, state, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const [loading, setLoading] = useState(false);

    async function handleBlock() {
        setLoading(true);

        const response = await bloquear();

        setLoading(false);

        if (response.status === 200) {
            setState({
                ...state,
                acaoBloquearCartao: false,
                successGeral: {
                    active: true,
                    title: "Bloqueio",
                    message: "Bloqueado com sucesso",
                },
            });
        }
    }

    return (
        <Container>
            <img className="img-close" src={imgError} alt="" />

            <Text as="h3">BLOQUEIO TEMPORÁRIO</Text>

            <Text as="p">
                Utilize essa função quando desejar bloquear temporariamente seu
                cartão. Você pode desfazer essa ação a qualquer momento.
            </Text>

            <Text as="p">
                Você confirma o bloqueio do cartão com final <br />
                <strong>
                    {cartao?.tipo === "cartao_fisico" ||
                    cartao?.tipo === "cartao_avulso"
                        ? cartao.acg_account_card_last4digits
                        : ""}
                </strong>
                ?
            </Text>

            <Button
                onClick={async () => handleBlock()}
                isLoading={loading}
                type="submit"
                spinerColor="primary"
                spiner="BallTriangle"
                mb="32px"
            >
                SIM, SEGUIR COM O BLOQUEIO <br />
                TEMPORÁRIO
            </Button>

            <Button onClick={() => handleClose("acaoBloquearCartao")}>
                NÃO EFETUAR BLOQUEIO TEMPORÁRIO
            </Button>
        </Container>
    );
}
