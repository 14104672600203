import { Container } from "./styles";
import imgX from "../../../../../assets/img/x-white-list.svg";
import { useWhiteList } from "../../../../../Hook/system/useWhiteList";

export function MotivoReprovar() {
    const { motivoReprovar } = useWhiteList();

    return (
        <Container>
            <div className="container-check">
                <div className="container-img">
                    <img src={imgX} alt="" />
                </div>
            </div>

            <div className="container-text">
                <h3>Motivo da reprovação</h3>

                <div className="container-description">
                    <p>{motivoReprovar?.text}</p>
                </div>
            </div>
        </Container>
    );
}
